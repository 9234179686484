import { csvDownload } from '../../utils/components/downloadUtils';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilterPanel from '../common/filterPanel/FilterPanel';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import React, { useEffect, useState } from 'react';
import { config } from '../../config/Constants';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';
import Axios from 'axios';
import { getDownload } from '../../utils/helpers/getDownloadData';
import { excelDownload } from '../../utils/components/downloadUtils';

import './css/buttonController.css';

const ButtonController = ({ chartId }) => {
  const navigate = useNavigate();

  const [downloadButton, setDownloadButton] = useState(false);
  const [viewReportButton, setViewReportButton] = useState(false);
  const [filterPanel, setFilterPanel] = useState(false);
  const [filterToggle, setFilterToggle] = useState(false);
  const [excelButton, setExcelButton] = useState(false);

  useEffect(() => { getData(); }, []);

  const getData = async() => {
    const response = await Axios.get(config.url.GET_DASHBOARD_BUTTONS, {
      params: { chartId: chartId }
    });
    await getButtons(response.data.button);
  };

  const getButtons = async(buttons) => {
    const isDownloadButton = buttons.DOWNLOAD_BUTTON;
    const isViewReportButton = buttons.VIEW_REPORT_BUTTON;
    const isFilterPanel = buttons.FILTER_PANEL;
    const isExcelDownloadButton = buttons.EXCEL_DOWNLOAD_BUTTON;

    if (isDownloadButton) { setDownloadButton(true); }
    if (isViewReportButton) { setViewReportButton(true); }
    if (isFilterPanel) { setFilterPanel(true); }
    if (isExcelDownloadButton) { setExcelButton(true); }
  }

  const downloadReport = async() => {
    const customer = Cookies.get('cp-cn');
    const response = await Axios.get(config.url.GET_DOWNLOAD_REPORT, {
      params: { chartId: chartId, customer: customer }
    });
      csvDownload(response)
  }

  const excelDownloadReport = async() => {
    const data = await getDownload(chartId);
    excelDownload(data[0], data[1]);
  }

  const viewReport = () => {
    if (chartId === 1) {
      navigate('/auditcenter');
    }
  }

  const viewPanel = () => { setFilterToggle((prevOpen) => !prevOpen); }

  return (
    <div className='button-container'>
      {downloadButton && (
        <div className='button-wrapper'>
          <Button onClick={downloadReport}>
            <FileDownloadIcon/>
          </Button>
        </div>
      )}
      {excelButton && (
        <div className='button-wrapper'>
          <Button onClick={excelDownloadReport}>
            <FileDownloadIcon/>
          </Button>
        </div>
      )}
      {viewReportButton && (
        <div className='button-wrapper'>
          <Button onClick={viewReport}>
            View Detailed Report
          </Button>
        </div>
      )}
      {filterPanel && (
        <div className='button-wrapper'>
          {filterToggle && <FilterPanel chartId={chartId}/>}
          <Button onClick={viewPanel}>
            <FilterAltIcon />
          </Button>
        </div>
      )}
    </div>
  )
}

export default ButtonController