import ReportCard from '../../../components/reportCard/ReportCard';
import usePageView from '../../../hooks/analytics/usePageView.js';
import Sidebar from '../../../components/sidebar/Sidebar';
import NavBar from '../../../components/navbar/NavBar';
import verifyToken from "../../../utils/tokenUtils.js";
import { config } from "../../../config/Constants.js";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './reportlanding.css';
import Axios from 'axios';

const ReportLanding = () => {
  usePageView()
  const navigate = useNavigate();
  const [report, setReport] = useState([{}]);

  const getUserName = async() => { return Cookies.get('cp-un'); }
  const getAuthToken = () => { return Cookies.get('gtms-cp-auth'); };

  useEffect(() => {
    const token = getAuthToken();
    token ? verifyToken(token) : navigate('/');
    getReports();
  }, []);

  const getReports = async () => {
    const customerName = await getUserName();
    const response = await Axios.get(config.url.GET_REPORTS, {
      params: { customerName: customerName, }
    });
    setReport(response.data.reports);
  }

  const handleClick = async (url) => {
    navigate(`/reports/${url}`); 
  }

  return (
    <div className='LandingPage'>
      <div className="LandingPageContainer">
        <NavBar/>
        <Sidebar/>
        <div className="trackingLandingpageContent">
          {report.map(reports =>
            <ReportCard
              key={reports.ID}
              title={reports.REPORT_NAME}
              description={reports.REPORT_DESCRIPTION}
              onClick={() => handleClick(reports.URL)}
              />
            )}
        </div>
      </div>
    </div>
  )
}

export default ReportLanding