import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import SavingsRoundedIcon from '@mui/icons-material/SavingsRounded';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import React from 'react';

const IconController = ({ iconType, fontSize = "3rem"}) => {
    let IconComponent;

    switch (iconType) {
      case 'AttachMoneyRoundedIcon':
        IconComponent = AttachMoneyRoundedIcon;
        break;
      case 'SavingsRoundedIcon':
        IconComponent = SavingsRoundedIcon;
        break;
      case 'localShippingIcon':
        IconComponent = LocalShippingIcon;
        break;
      default:
        return null;
    }

  return <IconComponent style={{ fontSize: fontSize }} />;
}

export default IconController