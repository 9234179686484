import { getCity } from '../../utils/api/saasLocation';
import React, { useState, useEffect } from 'react';

export const useFetchCities  = (country, state) => {
    const [cityList, setCityList] = useState([]);

    useEffect(() => {
      if (country && state) {
        const fetchData = async () => {
          const cities = await getCity(state);
          setCityList(cities);
        };
        fetchData();
      }
    }, [country, state]);
  
    return cityList;
  };