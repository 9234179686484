import gtmsLogo from '../../components/images/logo_gtms/gtms_logo.png';
import companyLogos from '../../utils/companyLogos.json';
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './customizedTitle2.css';

const CustomizedTitle = ({ titleObject }) => {
  const [title, setTitle] = useState(null);
  const [customerLogo, setCustomerLogo] = useState(null);
  const [companyName, setCompanyName] = useState(null);

    const getCompany = () => {
      setCompanyName(Cookies.get('cp-cn'));
    };

    useEffect(() => {
      getCompany();
      setTitle(titleObject.title)
      getLogo();
    }, [titleObject]);

    const getLogo = async() => {
      try {
        let logoFile;
        if (Cookies.get('cp-un') == 'demo') {
          logoFile = companyLogos['gtms']
        } else {
          logoFile = companyLogos[companyName];
        }
        const logoModule = await import(`../../assets/logos/${logoFile}`);
        setCustomerLogo(logoModule.default);
      } catch(error) {
        setCustomerLogo(null)
      }
    };

    const titleStyle = {
      color: companyName === 'Purple' ? 'purple' : 'black'
    }

  return (
    <div className='customizedTitle2'>
      <div className='customerLogo2'>
        {customerLogo && <img src={customerLogo} alt="Company Logo" style={{height: '50px'}}/>}
      </div>
      <div className="centerTitle2">
        <h1 style={titleStyle}>{title}</h1>
      </div>
      <div className="customTitlepowerLogo2">
        <div className="customTitlegtmsPower2">
          <p>Powered By:</p>
        </div>
          <img src={gtmsLogo} className='gtmsimg' alt="GTMS Logo" />
      </div>
    </div>
  );
};

export default CustomizedTitle;
