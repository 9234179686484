import { ComposableMap, Geographies, Geography, Marker} from "react-simple-maps";
import companyPreferences from '../../../utils/companyPreferences.json';
import React, { useState, useEffect  } from "react";
import Cookies from 'js-cookie';

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const UsaChart = ({chartData}) => {
  const company = Cookies.get('cp-cn');
  const [hoveredCity, setHoveredCity] = useState(null);
  const [frequency, setFrequency] = useState(null);
  const [mapColor, setMapColor] = useState('#E9E3DA');
  const [markerColor, setMarkerColor] = useState('#FFFFFF')

  useEffect(() => {
    const secondaryColor = companyPreferences?.[company]?.colors?.secondaryColor;
    const primaryColor = companyPreferences?.[company]?.colors?.primaryColor;
    if (secondaryColor) {
      setMapColor(secondaryColor);
      setMarkerColor(primaryColor);
    }
  }, [companyPreferences, company]);

  return (
   <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%" }}>
      <ComposableMap projection="geoAlbersUsa" >
        <Geographies geography={geoUrl}>
          {({ geographies, outline, borders }) => (
            <>
              <Geography geography={outline} fill="#E9E3DA" />
              <Geography geography={borders} fill="none" stroke="#000000" />
              {geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={mapColor}
                />
              ))}
            </>
          )}
        </Geographies>
        {chartData.data.map(({ ID, CITY, FREQUENCY, LATITUDE, LONGITUDE }) => (
          <Marker
            key={ID}
            coordinates={[LONGITUDE, LATITUDE]}
            onMouseEnter={() => {setHoveredCity(CITY); setFrequency(FREQUENCY)}}
            onMouseLeave={() => setHoveredCity(null)}
          >
            <circle r={20} fill={markerColor} stroke="#fff" strokeWidth={2} />
          </Marker>
        ))}
      </ComposableMap>
      {hoveredCity && (
        <div style={{ position: "absolute", color: "black", padding: "5px" }}>
          City: {hoveredCity} | Shipments: {frequency}
        </div>
      )}
    </div>
  );
};

export default UsaChart;