import useFetchVendorName from '../../../../hooks/forms/biovations/useFetchVendorName';
import TextField from '@mui/material/TextField';
import React, { useState, useEffect } from 'react';
import './css/biovationsDomestic.css';
import Cookies from 'js-cookie';

const StepOne = ({ formData, onChange }) => {
  const [localFormData, setLocalFormData] = useState(formData);
  const vendor = useFetchVendorName(Cookies.get('cp-un'));

  const handleChange = (e) => {
    let {id, value} = e.target;
    setLocalFormData(prevState => ({ ...prevState, [id]: value }));
    onChange({...localFormData, [id]: value})
  }

  //TODO: Think about making a hook?
  useEffect(() => {
    if (vendor && vendor[0] && vendor[0].VENDOR_NAME) {
      const updatedFormData = { ...localFormData, companyName: vendor[0].VENDOR_NAME };
      setLocalFormData(updatedFormData);
      onChange(updatedFormData);
    } else {
      const updatedFormData = { ...localFormData, companyName: 'N/A' };
      setLocalFormData(updatedFormData);
      onChange(updatedFormData);
    }
  }, [vendor]);
  
  return (
    <div className="BiovationDomStepOne">
      <div className="BiovationFormContainer">
      <div className="BiovationFormFields">
        <div className="BiovationDomField">
          <TextField  size="small" id="companyName" label="Company Name" 
            variant="outlined" value={vendor && vendor[0].VENDOR_NAME  || 'N/A'} onChange={handleChange} 
            required disabled
          />
        </div>
        <div className="BiovationDomField">
          <TextField  size="small" id="companyContact" label="Company Contact" 
            variant="outlined" value={formData.companyContact || ''} onChange={handleChange}
            required
          />
        </div>
        <div className="BiovationDomField">
          <TextField size="small" id="companyEmail" label="Company Email" 
            variant="outlined" value={formData.companyEmail || ''} onChange={handleChange}
            required
          />
        </div>
        <div className="BiovationDomField">
          <TextField size="small" id="reference1" label="PO Number" 
            variant="outlined" value={formData.reference1 || ''} onChange={handleChange}
            required
          />
        </div>
        <div className="BiovationDomField">
          <TextField  size="small" id="reference2" label="Reference Number" 
            variant="outlined" value={formData.reference2 || ''} onChange={handleChange}
          />
          </div>
        </div>
      </div>
    </div>
  )
}

export default StepOne