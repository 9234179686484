import Axios from 'axios';
import { config } from '../../config/Constants';

export const getCountry = async () => {
  try {
    const response = await Axios.get(config.url.GET_SAAS_DOMESTIC_COUNTRY);
    return response.data.domesticCountries;
  } catch (error) {
    console.error('Error fetching countries:', error);
    return [];
  }
};

export const getState = async (country) => {
  try {
    const response = await Axios.get(config.url.GET_SAAS_STATE, {
      params: { country: country }
    });
    return response.data.states;
  } catch (error) {
    console.error('Error fetching states:', error);
    return [];
  }
};

export const getCity = async (state) => {
  try {
    const response = await Axios.get(config.url.GET_SAAS_CITIES, {
      params: { state: state }
    });
    return response.data.cities;
  } catch (error) {
    console.error('Error fetching cities:', error);
    return [];
  }
};

export const getPostal = async (state, city) => {
  try {
    const response = await Axios.get(config.url.GET_SAAS_POSTAL, {
      params: {
        state: state,
        city: city
      }
    });
    return response.data.postals;
  } catch (error) {
    console.error('Error fetching postal codes:', error);
    return [];
  }
};
