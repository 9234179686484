import EditableDataTable from '../../../../components/tables/EditableDataTable';
import Dropbox from '../../../../components/files/dropbox/Dropbox';
import usePageView from '../../../../hooks/analytics/usePageView';
import { config } from '../../../../config/Constants';
import { Button, TextField } from '@mui/material';
import React, {useState} from 'react';
import Swal from 'sweetalert2';
import Axios from 'axios';
import './loadquote.css';

const LoadQuote = () => {
  usePageView();
  const [fileInfo, setFileInfo] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [bolNumber, setBOLNumber] = useState(null);

  const columns = [
    { key: '1', label: 'Description' },
    { key: '2', label: 'Cost' },
  ];

  const handleRowDataChange = (updatedRowData) => {
    setRowData(updatedRowData);
  };

  const validation = () => {
    let isValidated = true;
    const allObjectsEmpty = rowData.every(obj => Object.keys(obj).length === 0);

    if (fileInfo === null) {
      isValidated = false;
      Swal.fire({ title: 'Error: Include file in submisison', confirmButtonColor: '#1769aa', });
    } else if (bolNumber === null ) {
      isValidated = false;
      Swal.fire({ title: 'Error: Include BOL in submission', confirmButtonColor: '#1769aa', });
    } else if (allObjectsEmpty) {
      isValidated = false;
      Swal.fire({ title: 'Error: Include Description and Cost', confirmButtonColor: '#1769aa', });
    }

    return isValidated;
  }

  const submitForm = async() => {
    const response = await Axios.post(config.url.SUBMIT_LOAD_QUOTE, {
      bolNumber: bolNumber,
      costDescRowData: rowData,
      fileInfo: fileInfo
    });
    return response.data.success;
  }

  const popUpMessage = (isSuccess) => {
    const icon = isSuccess ? 'success' : 'error';
    const title = isSuccess ? 'Form Submitted Successfully' : 'Error: Form unable to process';

    Swal.fire({
      icon: icon,
      title: title,
      confirmButtonColor: '#1769aa',
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    })
  }

  const handleSubmit = async() => {
    const isValidated= validation();
    if (isValidated === true) {
      const isSuccess = await submitForm();
      popUpMessage(isSuccess);
    }
  }

  return (
    <div className='loadQuotePage'>
      <div className='loadQuoteContainer'>
      <div className="loadQuoteTop">
      </div>
      <div className="loadQuoteMid">
        <div className="loadQuoteForm">
          <div className="loadQuoteFormTop">
            <div className="formTitle">
              <h1>Load Quote Form</h1>
            </div>
            {/*<div className="helpDocument">
              <Button 
                variant='outlined' 
                style={{ color: 'blue', fontSize: '12px' }}>
                  Form Documentation
              </Button>
            </div>*/}
          </div>
          <div className="loadQuoteFormMid">
            <div className="loadQuoteKeyField">
              <div className="loadQuoteLabel">
                <h4>BOL Number:</h4>
              </div>
              <div className="loadQuoteInput">
                <TextField onChange={e => setBOLNumber(e.target.value)} size="small"/>
              </div>
            </div>
            <div className="flex-container">
              <div className="loadQuoteCostTable">
                <EditableDataTable columns={columns} onRowDataChange={handleRowDataChange} />
              </div>
            </div>
            <div className="loadQuoteFileDrop">
              <div className="loadQuoteDropbox">
                <Dropbox setFileInfo={setFileInfo}/>
              </div>
            </div>
          </div>
          <div className="loadQuoteFormBottom">
            <div className="loadQuoteSubmit">
              <Button variant="contained" onClick={handleSubmit} >Submit</Button>
            </div>
          </div>
        </div>
      </div>
      <div className="loadQuoteBottom"></div>
      </div>
    </div>
  )
}

export default LoadQuote