import { MapContainer, TileLayer, Marker, Polyline, Popup } from 'react-leaflet';
import React, { useEffect, useRef } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import icon from 'leaflet/dist/images/marker-icon.png';

let DefaultIcon = L.icon({
  iconUrl: icon,
  iconSize: [25, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const Map = ({coordinates}) => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) {
      if (coordinates.length === 1) {
        mapRef.current.setView([coordinates[0].LATITUDE, coordinates[0].LONGITUDE], 10);
      }
      else {
        const lastCoordinate = coordinates[coordinates.length - 1];
        mapRef.current.setView([lastCoordinate.LATITUDE, lastCoordinate.LONGITUDE], 5);
      }
    }
  }, [coordinates[0].LATITUDE, coordinates[0].LONGITUDE]);

  return (
    <MapContainer 
      ref={mapRef}
      center={[coordinates[0].LATITUDE, coordinates[0].LONGITUDE]} 
      zoom={10} 
      style={{ height: '600px' }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="Map data &copy; <a href='https://www.openstreetmap.org/'>OpenStreetMap</a> contributors"
      />

      {coordinates.map((coordinate, index) => (
        <Marker 
          position={[coordinate.LATITUDE, coordinate.LONGITUDE]} key={index}>
            <Popup>{coordinate.POSITION_DATETIME}</Popup>
        </Marker>
      ))}

      <Polyline positions={coordinates.map(coordinate => [coordinate.LATITUDE, coordinate.LONGITUDE])}/>
    </MapContainer>
  );
};

export default Map;
