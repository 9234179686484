import DownloadButton from '../../../components/common/button/DownloadButton';
import { getFilteredReport, getReport } from '../../../utils/api/reportData';
import DynamicTable from '../../../components/tables/dynamicTable';
import usePageView from '../../../hooks/analytics/usePageView.js';
import SelectBox from '../../../components/common/SelectBox';
import Sidebar from '../../../components/sidebar/Sidebar';
import { getSelectData } from '../../../utils/api/data';
import NavBar from '../../../components/navbar/NavBar';
import verifyToken from "../../../utils/tokenUtils.js";
import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './chargeDescription.css';
import Cookies from 'js-cookie';

const ChargeDescription = () => {
  usePageView();
  const navigate = useNavigate();
  const [reportData, setReportData] = useState(null);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectValue, setSelectValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);

  const getAuthToken = () => { return Cookies.get('gtms-cp-auth'); };

  const selectObject = {label: 'Carrier', menuItems: selectOptions}
  const handleSelectValue = (selectedValue) => { setSelectValue(selectedValue); }

  const fetchData = async() => {
    const data = await getReport('chargedescription', Cookies.get('cp-cn'))
    setReportData( {data: data, size: 'small'});
    setLoading(false);
  
    const responseSelect = await getSelectData({
      selectValues: '"Carrier"', tableName: 'CUSTOMER_PORTAL.REPORT.CHARGE_DESCRIPTIONS',
      whereField: 'Customer', whereValue: Cookies.get('cp-cn'), db: 'SF'
    });
    const carrierList = responseSelect.map(item => item.Carrier);
    setSelectOptions(carrierList)
  }

  useEffect(() => { 
    const token = getAuthToken();
    token ? verifyToken(token) : navigate('/');
    fetchData(); 
  }, []);

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') { handleSubmit(); }
  };

  const handleSubmit = async() => {
    const filteredValues = {carrier: selectValue, chargeDescription: searchValue, db: 'SF'}
    const filteredata = await getFilteredReport('chargedescription', Cookies.get('cp-cn'), filteredValues)
    setReportData({data: filteredata, size: 'small'})
  }

  return (
    <div className="chargeDescriptionPage">
      <div className="LandingPageContainer">
        <NavBar/>
        <Sidebar/>
        <div className="chargeDescriptionPageContent">
          <div className="topChargeDescription">
            <h1>Charge Description Report</h1>
          </div>
          <div className="midChargeDescription">
            <div className="chargeDescriptionReport">
              {loading ? (
                <p>Loading Report...</p>
              ) : (
                <DynamicTable dataObject={reportData} />
              )}
            </div>
            <div className="chargeDescriptionFilter">
              <div className="chargeDescriptionCarrier">
                <SelectBox selectValue={handleSelectValue} selectObject={selectObject}/>
              </div>
              <div className="chargeDescriptionSearch">
                <TextField 
                  label="Search Charge Description" 
                  value={searchValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div className="chargeDescriptionSubmit">
                <Button variant='contained' onClick={handleSubmit}>Submit</Button>
              </div>
            </div>
          </div>
          <div className="bottomChargeDescription">
            <DownloadButton data={reportData} fileName={'Charge-Description'} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChargeDescription