import ReportCard from '../../../components/reportCard/ReportCard';
import usePageView from '../../../hooks/analytics/usePageView';
import Sidebar from '../../../components/sidebar/Sidebar';
import NavBar from '../../../components/navbar/NavBar';
import verifyToken from '../../../utils/tokenUtils';
import { config } from '../../../config/Constants';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Axios from 'axios';
import './formsLanding.css';

const FormsLanding = () => {
  usePageView();

  const navigate = useNavigate();
  const [forms, setforms] = useState([{}]);
  const getCustomer = async() => { return Cookies.get('cp-cn'); };
  const getAuthToken = () => { return Cookies.get('gtms-cp-auth'); };

  useEffect(() => {
    const token = getAuthToken();
    token ? verifyToken(token) : navigate('/');
    getReports();
  }, []);

  const getReports = async () => {
    const customer = await getCustomer()
    const response = await Axios.get(config.url.GET_FORMS, {
      params: { companyName: customer, }
    });
    setforms(response.data.forms);
  }

  const handleClick = async (id) => { 
    navigate(`/forms/${id}`); 
  };

  return (
    <div className='LandingPage'>
      <div className="LandingPageContainer">
        <NavBar/>
        <Sidebar/>
        <div className="FormLandingPageContent">
          {forms.map(forms =>
            <ReportCard
              key={forms.ID}
              title={forms.FORM_NAME}
              description={forms.FORM_DESCRIPTION}
              onClick={() => handleClick(forms.ID)}
              />
            )}
        </div>
      </div>
    </div>
  )
}

export default FormsLanding