import devConfig from './config-dev';
import prodConfig from './config-prod';
import testConfig from './config-test';

let config;

if (process.env.NODE_ENV === 'development') {
    config = devConfig;
} else if (process.env.ENVIROMENT_STAGE === 'production') {
    config = prodConfig;
} else if (process.env.ENVIROMENT_STAGE === 'test') {
    config = testConfig;
} else {
    config = prodConfig;
}

export { config };