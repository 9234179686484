import { TextField } from '@mui/material';
import React from 'react';

const SearchBar = ({ inputValue, onSearch, config }) => {
  const size = config && config.size ? config.size : 'small';
  const label = config && config.label ? config.label: 'Search';

  const handleInputChange = (event) => {
    const value = event.target.value;
    inputValue(value);
  }

  const handleKeyPress = (event) => {
    if (config.keyPress && event.key === 'Enter') {
      onSearch(event.target.value);
    }
  }

  return (
    <div className="searchBar">
      <TextField
        size={size}
        id="Search"
        label={label}
        fullWidth
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}/>
    </div>
  )
}

export default SearchBar