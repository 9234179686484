import { createSlice } from "@reduxjs/toolkit";

const initialState = { summaryData: {}, };

const summaryDataSlice = createSlice({
    name: 'summaryData',
    initialState,
    reducers: {
        setSummaryData: ( state, action ) => {
            state.summaryData = action.summaryData;
        },
    },
});

export const { setSummaryData } = summaryDataSlice.actions;
export default summaryDataSlice.reducer;