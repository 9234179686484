import { getState } from '../../utils/api/saasLocation';
import React, { useState, useEffect } from 'react';

export const useFetchStates = (country) => {
    const [StateList, setStateList] = useState([]);
  
    useEffect(() => {
        if (country) {
          const fetchData = async () => {
            const states = await getState(country);
            setStateList(states);
          };
          fetchData();
        }
      }, [country]);
  
    return StateList;
  };