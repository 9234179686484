import SelectBox from '../../../../components/common/SelectBox';
import { TextField } from '@material-ui/core';
import React from 'react';
import './css/k2j.css'

const RequestingCompany = ({formData, handleChange, poList}) => {
  const selectPo = { label: 'PO Number', menuItems: poList.map(obj => obj.PO_NUMBER)}
  return (
    <div className="DomesticRequestingCompany">
      <div className="RequestingCompanyFormContainer">
      <div className="RequestingCompanyFormFields">
        <div className="RequestingCompanyDomField">
          <TextField 
            size="small" 
            id="companyName" 
            label="Company Name" 
            variant="outlined"
            value={formData?.companyName || ''}
            onChange={(e) => handleChange(e, 'requestingCompany')}
            required
          />
        </div>
        <div className="RequestingCompanyDomField">
          <TextField 
            size="small" 
            id="companyContact" 
            label="Company Contact" 
            variant="outlined"
            value={formData?.companyContact || ''}
            onChange={(e) => handleChange(e, 'requestingCompany')}
            required
          />
        </div>
        <div className="RequestingCompanyDomField">
          <TextField 
            size="small" 
            id="companyEmail" 
            label="Company Email" 
            variant="outlined"
            value={formData?.companyEmail || ''}
            onChange={(e) => handleChange(e, 'requestingCompany')}
            required
          />
        </div>
        <div className="RequestingCompanyDomField">
          <SelectBox               
            selectValue={(value) => handleChange({ target: { id: 'po', value: value } }, 'requestingCompany')} 
            selectObject={selectPo}
          />
        </div>
        <div className="RequestingCompanyDomField">
          <TextField 
            size="small" 
            id="reference1" 
            label="Reference Number" 
            variant="outlined"
            value={formData?.reference1 || ''}
            onChange={(e) => handleChange(e, 'requestingCompany')}
          />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RequestingCompany