import React from 'react';
import Papa from 'papaparse';
import FileDownload from 'js-file-download';
import { Button } from '@mui/material';

const DownloadButton = ({data, fileName}) => {

  const handleDownload = () => {
    const csv = Papa.unparse(data);
    FileDownload(csv, `${fileName}-${Date.now()}.csv`);
  }

  return (
    <div className="DownloadButton">
      <Button 
        variant='outlined' 
        sx={{ width: '200px' }} 
        onClick={handleDownload}>
          Download Report
      </Button>
    </div>
  )
}

export default DownloadButton