import { useState, useEffect } from 'react';

const useBiovationForm = () => {
  const [customerLogo, setCustomerLogo] = useState(null);

  const getLogo = async () => {
    try {
      const logoModule = await import(`../../../assets/logos/Biovation-labs-logo.png`);
      setCustomerLogo(logoModule.default);
    } catch (error) {
      setCustomerLogo(null);
    }
  };

  useEffect(() => {
    getLogo();
  }, []);

  return { customerLogo };
};

export default useBiovationForm;
