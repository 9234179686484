import usePageView from "../../../hooks/analytics/usePageView.js";
import verifyToken from "../../../utils/tokenUtils.js";
import { config } from "../../../config/Constants.js";
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import ReactGA from 'react-ga4';
import Swal from 'sweetalert2';
import './resetpassword.css';
import Axios from 'axios';


const ResetPassword = () => {
  usePageView();
  const navigate = useNavigate();
  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();
  const [email, setEmail] = useState();
  const [token, setToken] = useState();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const email = urlParams.get('email');
    setEmail(email);
    setToken(token);

    token ? verifyToken(token) : navigate('/');
  }, []);

  const resetPassword = async() => {
    ReactGA.event({ category: 'Button', action: 'Click', label: 'Reset Password Button', value: 1 });
    token ? verifyToken(token) : navigate('/');
    if (password && passwordConfirm) {
      const response = await Axios.post(config.url.RESET_PASSWORD, {
        password: password,
        passwordConfirm: passwordConfirm,
        email: email,
      });

      response.data.isValidPassword ? handleSuccess() : handleError();
  }
}

const handleSuccess = () => {
  Swal.fire({
    icon: 'success',
    title: 'Password Reset.',
  });
};

const handleError = () => {
  Swal.fire({
    icon: 'error',
    title: 'Failed to reset password. Try Again Later.',
  });
};

const toPortal = () => {
  navigate('/')
}

  return (
    <div className="resetPasswordPage">
      <div className="resetPasswordContainer">
        <div className="resetPasswordTop">
          <div className="resetPasswordTitle">
            <h1>Reset Password</h1>
          </div>
        </div>
        <div className="resetPasswordMid">
          <div className="resetPasswordTextFields">
            <div className="resetPasswordPassword">
              <TextField
                id="resetPasswordEmail"
                label="Password"
                variant="outlined"
                type="password"
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <div className="resetPasswordConfirmPassword">
              <TextField
                id="resetPasswordEmailConfirm"
                label="Confirm Password"
                variant="outlined"
                type="password"
                onChange={e => setPasswordConfirm(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="resetPasswordBottom">
          <div className="resetPasswordButtons">
            <div className="resetPasswordSubmit">
              <Button
                variant="contained"
                onClick={resetPassword}>
                Submit
              </Button>
            </div>
            <div className="resetPasswordBack">
              <Button
                variant="outlined"
                onClick={toPortal}>
                To Customer Portal
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}

export default ResetPassword