//import useFetchOriginGeo from '../../../../hooks/forms/biovations/useFetchOriginGeo.js';
import { useFetchCountries } from '../../../../hooks/geo/useFetchCountries.js';
import { useFetchCities } from '../../../../hooks/geo/useFetchCities.js';
import { useFetchPostal } from '../../../../hooks/geo/useFetchPostal.js';
import { useFetchStates } from '../../../../hooks/geo/useFetchStates.js';
import SelectBox from '../../../../components/common/SelectBox';
import React from 'react';

const OriginLocation = ({formData, handleChange}) => {
  const countryList = useFetchCountries();
  const stateList = useFetchStates(formData.originCountry);
  const cityList = useFetchCities(formData.originCountry, formData.originState);
  const postalList = useFetchPostal(formData.originCountry, formData.originState, formData.originCity);

  const selectCountry = { label: 'Country', menuItems: countryList || []}
  const selectState = { label: 'State', menuItems: stateList}
  const selectCity = { label: 'City', menuItems: cityList}
  const selectPostal = { label: 'Postal', menuItems: postalList}

  return (
    <div className="OriginLocation">
      <div className="OriginLocationFormContainer">
        <div className="OriginLocationFormFields">
          <div id='originCountry' className="OriginLocationField">
            <SelectBox 
              selectValue={(value) => handleChange({ target: { id: 'originCountry', value } }, 'originLocation')}
              selectObject={selectCountry} 
              initialValue={formData.originCountry}
            />
          </div>
          <div className="OriginLocationField">
            <SelectBox
              selectValue={(value) => handleChange({ target: { id: 'originState', value } }, 'originLocation')}
              selectObject={selectState}
              initialValue={formData.originState}
            />
          </div>
          <div className="OriginLocationField">
            <SelectBox
              selectValue={(value) => handleChange({ target: { id: 'originCity', value } }, 'originLocation')}
              selectObject={selectCity}
              initialValue={formData.originCity}
            />
          </div>
          <div className="OriginLocationField">
            <SelectBox
              selectValue={(value) => handleChange({ target: { id: 'originPostal', value } }, 'originLocation')}
              selectObject={selectPostal}
              initialValue={formData.originPostal}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OriginLocation