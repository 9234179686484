import StackedBar from '../../components/charts/bar/stackedBar/StackedBar';
import MultiHeaderTable from '../../components/tables/MultiHeaderTable';
import FileExplorer from '../../components/fileExplorer/FileExplorer';
import UsaChart from '../../components/charts/geographical/Usa';
import SimpleTable from '../../components/tables/dynamicTable';
import PieChart from '../../components/charts/pie/piechart';
import Badges from '../../components/common/badges/Badges';
import PieChartSummary from '../../components/charts/pie/PieChartSummary';

const getChart = async(chartData, setHeight) => {
    let chartComponent;
    
    if (chartData.chartInfo) {
      switch (chartData.chartInfo[0].TYPE) {
        case 'STACKEDBAR':
          chartComponent = <StackedBar chartData={chartData.data} />;
          break;
        case 'DOWNLOADTABLE':
          const fileObject = {
            fileData: { fileObject: chartData.data },
            fileExplorerAttribute :{
              fileHeaders: ['File Name', 'Date', 'Download'], fileButtons: ['Remit Download']
            }
          };
          chartComponent = <FileExplorer fileObject={fileObject} />;
          break;
        case 'PIE':
          chartComponent = <PieChart chartData={chartData.data}/>;
          break;
        case 'PIECHARTSUMMARY':
          chartComponent = <PieChartSummary chartData={chartData.data.chart1} secondaryChart={chartData.data.chart2}/>
          break;
        case 'ONBOARDING':
          chartComponent = <p>Welcome to the Portal.<br/>
            Currently there is no component available.<br/>
            Please contact GTMS to specify what you want shown here.</p>;
          break;
        case 'MULTIHEADERTABLE':
          chartComponent = <MultiHeaderTable chartData={chartData.data} /> 
          break;
        case 'BADGES': 
          setHeight('auto');
          chartComponent = <Badges chartData={chartData.data} />
          break;
        case 'TABLE':
          chartComponent = <SimpleTable dataObject={chartData} />
          break;
        case 'USACHART':
          chartComponent = <UsaChart chartData={chartData} />
          break;
        default:
          chartComponent = <p>Error fetching chart</p>;
      }
    } else {
      chartComponent = 'Error fetching chart data';
    }
    return chartComponent;
  }

export default getChart;