import React from 'react';
import './reportcard.css';

const ReportCard = ({ title, description, onClick }) => {
  return (
    <div className="reportCard" onClick={onClick}>
      <div className="reportCardContent">
        <div className="reportCardTitle">
          <h1>{title}</h1>
        </div>
        <div className="reportCardDescription">
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default ReportCard;