import usePageView from '../../../hooks/analytics/usePageView';
import CircularProgress from '@mui/material/CircularProgress';
import SearchBar from '../../../components/common/SearchBar';
import Table from '../../../components/tables/dynamicTable';
import Sidebar from '../../../components/sidebar/Sidebar';
import NavBar from '../../../components/navbar/NavBar';
import { config } from '../../../config/Constants';
import { Button} from '@mui/material';
import React, {useState} from 'react';
import Cookies from 'js-cookie';
import './tnCostBreakdown.css';
import Axios from 'axios';

const TnCostBreakdown = () => {
  usePageView();
  const [searchValue, setSearchValue] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isData, setIsData] = useState(false);

  const searchConfig = {size: 'normal', keyPress: true, label: 'Search Tracking Number'}
  const inputValue = (value) => { setSearchValue(value); };
  
  const onSearch = (value) => {
    setSearchValue(value);
    handleSubmit();
  }

  const getBreakdown = async() =>  {
    const response = await Axios.get(config.url.GET_REPORT, {
      params: {
        reportName: 'trackingcostbreakdown',
        trackingNumber: searchValue,
        companyName: Cookies.get('cp-cn')
      }
    });
    return response.data.report;
  }

  const handleSubmit = async() => {
    setIsLoading(true);
    setIsData(true);
    const breakdown = await getBreakdown();
    setTableData(breakdown);
    setIsLoading(false);
  }

  return (
    <div className='tnCostBreakdownPage'> 
      <div className="LandingPageContainer">
        <NavBar/>
        <Sidebar/>
        <div className="tnCostBreakdownPageContent">
          <div className="tnCostBreakdownMid">
            <div className="tnCostBreakdownTitle">
              <h1>Tracking Number Cost Breakdown</h1>
            </div>
            <div className="CostBreakdownSearchControls">
              <div className="tnCostBreakdownSearch">
                <SearchBar inputValue={inputValue} onSearch={onSearch} config={searchConfig}/>
              </div>
              <div className="tnCostBreakdownButton">
                <Button variant='contained' onClick={handleSubmit} > 
                  Submit
                </Button>
              </div>
            </div>
            <div className="horizontalLine"><hr/></div>
          </div>
          {isData && (
            <div className="tnCostBreakdownTable">
              {isLoading ? (
                <CircularProgress />
              ) : (
                  <Table dataObject={{ data: tableData }}/>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TnCostBreakdown