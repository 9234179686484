import { TextField, Button, MenuItem, Select, InputLabel, FormControl, Autocomplete } from '@mui/material';
import Sidebar from '../../../components/sidebar/Sidebar';
import NavBar from '../../../components/navbar/NavBar';
import verifyToken from "../../../utils/tokenUtils.js";
import verifyAdmin from '../../../utils/adminUtils';
import React, { useEffect, useState } from 'react';
import { config } from '../../../config/Constants';
import { useNavigate } from 'react-router-dom';
import './companyInformation.css';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import Axios from 'axios';

const CompanyInformation = () => {
  const navigate = useNavigate();

  const [operation, setOperation] = useState('');
  const [selectCustomerName, setSelectCustomerName] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [shortName, setShortName] = useState('');
  const [isActive, setIsActive] = useState(0);
  const [isAdmin, setIsAdmin] = useState(0);
  const [companyNames, setCompanyNames] = useState(['']);
  const [fieldAccess, setFieldsAccess] = useState(false);
  const [updateButtonCommand, setUpdateButtonCommand] = useState(false);
  const [insertButtonCommand, setInsertButtonCommand] = useState(false);

  const getAuthToken = () => {
    return Cookies.get('gtms-cp-auth');
  };

  const handleSelect = async(field, value) => {
    await field === 'operation'? setOperation(value) : setSelectCustomerName(value);
  };

  const fetchCompanies = async() => {
    if (operation === 0) {
        const companyAccounts = await getCompanies();
        setCompanyNames(companyAccounts);
    }
  }

  useEffect(() => {
    const token = getAuthToken();
    token ? verifyToken(token) : navigate('/');
    verifyAdmin(navigate);
    fetchCompanies();
  }, [operation]);

  const getCompanies = async() => {
    const response = await Axios.get(config.url.GET_COMPANIES);
    return response.data.companyAccounts;
  }

  const handleOperationButtonClick = async() => {
    if (operation === 0) {
      if (selectCustomerName) {
        setUpdateButtonCommand(true);
        setFieldsAccess(true);
      } else {
        setUpdateButtonCommand(false);
      }
    }

    if (operation === 1) {
      setInsertButtonCommand(true);
      setFieldsAccess(true);
    }
  }

  const handleButtonClick = async(field, value) => {
    if (operation === 0) {
      let record = { [field]: value, criteriaValue: selectCustomerName.CUSTOMER_NAME };
      const isSuccess = await updateField(record);
      notify(isSuccess);
    } 
  }

  const handleInsertButtonClick = async() => {
    if (customerName && customerId && shortName && isActive !== 'undefined' && isAdmin !== 'undefined') {
      const isSuccess = await insertRecord()
      notify(isSuccess);
    }
  }

  const notify = (isSuccess) => {
    const icon = isSuccess == true ? 'success' : 'error';
    const title = isSuccess == true ? 'Success' : 'Error';

    Swal.fire({
      icon,
      title,
      confirmButtonColor: '#0e60ed',
    });
  }

  const insertRecord = async() => {
    const insertObject = { 'customerName': customerName, 'customerId': customerId,
    'shortName': shortName, 'isActive': isActive, 'isAdmin': isAdmin, 'tableId': 1}

    const response = await Axios.post(config.url.ACCOUNT_INSERT_RECORD, {record: insertObject})
    return response.data.isSuccess
  }

  const updateField = async(record) => {
    record.whereField = 'CUSTOMER_NAME';
    record.tableId = 1;
    const response = await Axios.post(config.url.ACCOUNT_UPDATE_FIELD, {
      record: record
    });
    return response.data.isSuccess;
  };

  return (
    <div className="LandingPage">
      <div className="LandingPageContainer">
        <NavBar/>
        <Sidebar/>
        <div className="companyInformationPageContent">
          <div className="companyInformationTop">
            <div className="companyInformationTitle">
              <h1>Add Customer Account Permissions</h1>
            </div>
          </div>
          <div className="companyInformationMid">
            <div className="row">
              <div className="companyInformationSelect">
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="operation-label">Operation</InputLabel>
                  <Select 
                    labelId="operation-label" 
                    label="Operation?"
                    value={operation} 
                    onChange={(e) => {
                      const selectedValue = e.target.value != undefined ? e.target.value : '';
                      handleSelect('operation', selectedValue)}}>
                    <MenuItem value={0}>Edit</MenuItem>
                    <MenuItem value={1}>Insert</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="companyInformationSelect">
                  <Autocomplete
                    sx={{ m: 1, minWidth: 200 }}
                    disablePortal
                    id="accountInformationUser"
                    size="small"
                    getOptionLabel={(companyNames) => String(companyNames.CUSTOMER_NAME)}
                    options={companyNames}
                    renderInput={(params) => <TextField {...params} label="Select Customer"/>}
                    onChange={(event, selectCustomerName) => setSelectCustomerName(selectCustomerName)}
                    value={selectCustomerName === '' ? null : selectCustomerName}
                  />
              </div>
              <div className="companyInformationSubmit">
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => handleOperationButtonClick()}>
                  Submit
                </Button>
              </div>
            </div>
            <div className="dottedLineBreak"></div>
            <div className="row">
              <div className="companyInformationField">
                <TextField 
                  size="small"
                  id="companyNameCustomer" 
                  label="Customer Name"
                  variant="outlined"
                  disabled = {!fieldAccess}
                  onChange={e => setCustomerName(e.target.value)}/>
              </div>
              <div className="companyInformationSubmit">
                <Button
                  variant="contained"
                  size="small"
                  disabled = {!updateButtonCommand}
                  onClick={() => handleButtonClick('customerName', customerName)}>
                  Submit
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="companyInformationField">
                <TextField 
                  size="small"
                  id="customerId" 
                  label="Customer ID"
                  variant="outlined"
                  disabled = {!fieldAccess}
                  onChange={e => setCustomerId(e.target.value)}/>
              </div>
              <div className="companyInformationSubmit">
                <Button
                  variant="contained"
                  size="small"
                  disabled = {!updateButtonCommand}
                  onClick={() => handleButtonClick('customerId', customerId)}>
                  Submit
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="companyInformationField">
                <TextField 
                  size="small"
                  id="clientshortname" 
                  label="Client Short Name"
                  variant="outlined"
                  disabled = {!fieldAccess}
                  onChange={e => setShortName(e.target.value)}/>
              </div>
              <div className="companyInformationSubmit">
                <Button
                  variant="contained"
                  size="small"
                  disabled = {!updateButtonCommand}
                  onClick={() => handleButtonClick('clientshortname', shortName)}>
                  Submit
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="companyInformationSelect">
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="is-active-label">Is Active?</InputLabel>
                    <Select 
                      labelId="is-active-label" 
                      label="Is Active?"
                      disabled = {!fieldAccess}
                      onChange={e => setIsActive(e.target.value)}>
                      <MenuItem value={0}>No</MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                    </Select>
                  </FormControl>
              </div>
              <div className="companyInformationSubmit">
                <Button
                  variant="contained"
                  size="small"
                  disabled = {!updateButtonCommand}
                  onClick={() => handleButtonClick('isactive', isActive)}>
                  Submit
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="companyInformationSelect">
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="is-admin-label">Is Admin?</InputLabel>
                    <Select 
                      labelId="is-admin-label" 
                      label="Is Admin?"
                      disabled = {!fieldAccess}
                      onChange={e => setIsAdmin(e.target.value)}>
                      <MenuItem value={0}>No</MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                    </Select>
                  </FormControl>
              </div>
              <div className="companyInformationSubmit">
                <Button
                  variant="contained"
                  size="small"
                  disabled = {!updateButtonCommand}
                  onClick={() => handleButtonClick('isadmin', isAdmin)}>
                  Submit
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="emptyRow"></div>
              <div className="insertButton">
                <Button
                  variant="contained"
                  size="small"
                  disabled = {!insertButtonCommand}
                  onClick={() => handleInsertButtonClick()}>
                  Insert Customer
                </Button>
              </div>
            </div>
          </div>
          <div className="companyInformationBottom"></div>
        </div>
      </div>
    </div>
  )
}

export default CompanyInformation