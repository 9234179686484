import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const csvDownload = (response) => {
    if (response.status === 200) {
        const currentDate = new Date().toISOString().slice(0, 10); // Get current date in YYYY-MM-DD format
        const fileName = `report_${currentDate}.csv`;
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } else {
        alert('Failed to download report. Try Again.');
    }
}

export const excelDownload = (data, file) => {
    const workbook = XLSX.utils.book_new();

    for (const [key, value] of Object.entries(data)) {
      const worksheet = XLSX.utils.json_to_sheet(value);
      XLSX.utils.book_append_sheet(workbook, worksheet, key);
    }
    
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    saveAs(blob, `${file.name}.xlsx`);
}