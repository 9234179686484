import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import React, { useState, useEffect } from 'react';

const Toggle = ({toggleValue, toggleObject}) => {
  const [toggle, setToggle] = useState(null);

  useEffect(() => {
    setToggle(null); 
  }, [toggleObject.rows, toggleObject.tableSelection]);

  const handleToggleChange = (event, value) => {
    toggleValue(value);
    setToggle(value);
  }

  return (
    <div className="toggleButtonContainer">
      <ToggleButtonGroup
        color='primary'
        value={toggle}
        exclusive
        onChange={handleToggleChange}
        aria-label="Platform"
      >
        {toggleObject.toggleButtons.map((toggleItem, index) => (
          <ToggleButton key={index} value={toggleItem}>{toggleItem}</ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  )
}

export default Toggle