import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarData: [], 
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    storeSidebarData: (state, action) => {
      state.sidebarData = action.payload;
    },
  },
});

export const { storeSidebarData } = sidebarSlice.actions;
export default sidebarSlice.reducer;