import { Table, TableCell, TableContainer, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import React from 'react';

const MultiHeaderTable = (dataObject) => {
  // NOTE: To use table. Make sure to have dataObject in format of {[{}]}. 
  // Objects of arrays of object (Object #1: Header) (Array: SubHeaders) (Object: Data Values)

  const chartDataKeys = Object.keys(dataObject.chartData);
  const headers = chartDataKeys.length > 0 ? chartDataKeys : [];
  const subHeaders = chartDataKeys.length > 0 ? Object.keys(dataObject.chartData[chartDataKeys[0]][0]) : null;
  const MAX_TABLE_COLUMN= Object.keys(subHeaders).length;

  return (
    <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, '& th, & td': { padding: '8px' } }}aria-label="simple table">
          {headers.map((headerName, index) => (
            <React.Fragment key={index}>
              <TableRow>
                <TableCell colSpan={MAX_TABLE_COLUMN}  align="center"  style={{ backgroundColor: '#72A0C1'}}>
                  <strong>{headerName}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                {subHeaders.map((subHeaderName, subIndex) => (
                  <TableCell key={subIndex}  align="center"  style={{ backgroundColor: '#F0F8FF'}}>
                    <strong>{subHeaderName}</strong>
                  </TableCell>
                ))}
              </TableRow>
              {dataObject.chartData[headerName].map((fields, index) => (
                <TableRow>
                  {Object.keys(fields).map((key) => (
                    <TableCell align="center">{fields[key]}</TableCell>
                  ))}
                </TableRow>
              ))} 
            </React.Fragment>
          ))}
        </Table>
      </TableContainer>
    </div>
  )
}

export default MultiHeaderTable