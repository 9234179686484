import { List, ListItem, ListItemText } from '@mui/material';
import React from 'react';

const ListURL = ({ listObject }) => {
  if (!listObject || !Array.isArray(listObject.data) || listObject.data.length === 0) {
    return <p>Currently, no information is available.</p>
  }

  return (
    <div>
      <List>
        {listObject.data.map((item, index) => (
          <ListItem
            button
            key={index}
            target="_blank"
            component="a"
            href={item.url} >
              <ListItemText primary={`${listObject.listType} ${index + 1}`} />
          </ListItem>
        ))}
      </List>
    </div>
  )
}

export default ListURL