import LogoGtms from "../../../components/images/logo_gtms/LogoGtms";
import usePageView from "../../../hooks/analytics/usePageView.js";
import { config } from "../../../config/Constants.js";
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import React, { useState } from "react";
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';
import Swal from 'sweetalert2';
import Axios from 'axios';
import './login.css';

const Login = () => {
  usePageView();
  const navigate = useNavigate();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const getAuthToken = () => { return Cookies.get('gtms-cp-auth'); }

  const register = () => { 
    ReactGA.event({ category: 'Button', action: 'Click', label: 'Register Button', value: 1 });
    navigate('/register'); 
  }

  const forgotPassword = () => { 
    ReactGA.event({ category: 'Button', action: 'Click', label: 'Forgot Password Button', value: 1 });
    navigate('/forgotpassword');
  }

  const login = async() => {
    ReactGA.event({ category: 'Button', action: 'Click', label: 'Login Button', value: 1 });
    const response = await Axios.post(config.url.LOGIN_URL, {
      username: username,
      password: password,
    });

    const accessToken = response.data.accessToken;
    setAuthToken(accessToken);
    const isVerified = await verifyToken();
    navigateLanding(isVerified)
  };

  const navigateLanding = async(isVerified) => {
    if (isVerified === true) {
      const existingUser = await getUserCreds();
      const role = Cookies.get('cp-role');
      if (existingUser) {
        const page = await getLandingPage(role);
        navigate(page);
      } else {
        navigate('/unauthorized');
      }
    } else {
      Swal.fire({ icon: 'error', title: 'Incorrect username or password', });
      navigate('/');
    }
  }

  const verifyToken = async() => {
    const token = getAuthToken();
    const response = await Axios.post(config.url.VERIFY_TOKEN, { token: token, });
    return response.data.isVerified;
  }

  const getLandingPage = async(role) => {
    const response = await Axios.get(config.url.GET_LANDING_PAGE, { params: { role: role, }});
    return response.data.page;
  }

  const getUserCreds = async() => {
    let existingUser = false;
    const response = await Axios.post(config.url.GET_USER_CREDS, { username: username, });
    const userCreds = response.data.creds;
    if (userCreds) {
      existingUser = true;
      await setUserCreds(userCreds);
      ReactGA.set({ userId: userCreds.ID});
    }
    return existingUser
  }

  const setUserCreds = async (creds) => {
    Cookies.set('cp-un', creds.USERNAME, { expires: 1 });
    Cookies.set('cp-cn', creds.CUSTOMER_NAME, { expires: 1 });
    Cookies.set('cp-ci', creds.CUSTOMER_ID, { expires: 1 });
    Cookies.set('cp-csn', creds.CLIENT_SHORT_NAME, { expires: 1 });
    Cookies.set('cp-a', creds.IS_ACTIVE, { expires: 1 });
    Cookies.set('cp-admin', creds.IS_ADMIN, { expires: 1 });
    Cookies.set('cp-role', creds.ROLE_ID, { expires: 1 });
    Cookies.set('cp-vr', creds.VENDOR_NAME, { expires: 1 });
  }

  const setAuthToken = (token) => {
    Cookies.set('gtms-cp-auth', token, { secure: true, expires: 1 });
  }

  return (
  <div className="loginPage">
    <div className="loginContainer">
      <div className="loginTop">
        <div className="loginTitle">
          <h1>Customer Portal</h1>
          <LogoGtms/>
        </div>
      </div>
      <div className="loginMid">
        <div className="loginUsername">
          <TextField 
            style={{width: '100%'}}
            size="small"
            id="username"
            label="Username"
            variant="outlined"
            onChange={e => setUsername(e.target.value)}
          />
        </div>
        <div className="loginPassword">
          <TextField 
            style={{width: '100%'}}
            size="small"
            id="password"
            type="password"
            label="Password"
            variant="outlined"
            onChange={e => setPassword(e.target.value)}
          />
        </div>
      </div>
      <div className="loginBottom">
        <div className="loginButton">
          <Button
            variant="contained"
            onClick={login}>
            Login
          </Button>
        </div>
        <div className="loginAuthOptions">
          <div className="loginRegister">
            <p>New User? <a 
                className="signUpLink" 
                href="#" 
                onClick={register}>
                  Sign Up
              </a>
            </p>
          </div>
          <div className="line Break">
            <p>|</p>
          </div>
          <div className="forgotPassword">
            <a className="forgotPasswordLink"
              href="" 
              onClick={forgotPassword}>
              Forgot Password
            </a>
          </div>
        </div>
      </div>
    </div>
  </div> 
  )
}

export default Login