import logo from './gtms_logo.png';
import React from 'react';
import './image.css';

const Image = () => {
  return (
    <img src={logo} alt="Logo" />
  )
}

export default Image