import FileExplorer from '../../components/fileExplorer/FileExplorer';
import SearchBar from '../../components/common/SearchBar';
import Sidebar from '../../components/sidebar/Sidebar';
import NavBar from '../../components/navbar/NavBar';
import { config } from '../../config/Constants';
import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import Cookies from 'js-cookie';
import Axios from 'axios';
import './remit.css';

const Remit = () => {
    const pageId = 8;
    const [searchValue, setSearchValue] = useState('');
    const searchConfig = { size: 'normal', keyPress: true }

    const [blobObject, setBlobObject] = useState({
      blobContainer: 'remit-archive',
      fileData: {},
      fileExplorerAttribute :{
        fileHeaders: ['File Name', 'Modified Date', 'Created Date', 'Size', 'Action'],
        fileButtons: ['Download']
      },
    });

    const getCompany = () => {
      return Cookies.get('cp-cn');
    }

    useEffect(() => {
      const companyName = getCompany();
      const fetchRemits = async () => {
        const fileData = await getRemitFiles(companyName);
        setBlobObject(prevState => ({
          ...prevState,
          fileData: fileData,
        }));
      }
      fetchRemits();
    }, []);

    const getRemitFiles = async(companyName) => {
      let filesInfo;
      const response = await Axios.get(config.url.GET_REMIT_FILES, {
        params: {
          companyName: companyName,
          blobContainer: blobObject.blobContainer,
        },
      });
      filesInfo = {fileObject: response.data.fileObject}
      return filesInfo;
    }

    const searchRemit = async(companyName) => {
      const response = await Axios.get(config.url.GET_REMIT_SEARCH, {
        params: {
          companyName: companyName,
          blobContainer: blobObject.blobContainer,
          searchValue: searchValue,
        }
      });
      let filesInfo = {fileObject: response.data.fileObject}
      return filesInfo;
    }

    const handleRemitSearch = async() => {
      const companyName = getCompany();
      const fileData = await searchRemit(companyName);
      setBlobObject(prevState => ({
        ...prevState,
        fileData: fileData,
      }));
    }

    const inputValue = (value) => {
      setSearchValue(value);
    }

  return (
  <div className='LandingPage'>
    <div className="LandingPageContainer">
      <NavBar/>
      <Sidebar/>
      <div className="LandingPageContent">
        <div className="LandingPageContentTop">
          <div className="remitTitle">
              <h1>Remit Download</h1>
          </div>
          <div className="remitSearch">
            <div className="remitSearchBar">
              <SearchBar inputValue={inputValue} config={searchConfig}/>
            </div>
            <div className="remitSearchButton">
              <Button variant='outlined' onClick={() => handleRemitSearch()}>Search</Button>
            </div>
          </div>
        </div>
        <div className="LandingPageContentMid">
          <FileExplorer fileObject={blobObject} />
        </div>
        <div className="LandingPageContentBottom"></div>
      </div>
    </div>
  </div>
  )
}

export default Remit