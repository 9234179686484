import { Button, MenuItem, Select, InputLabel, FormControl, Autocomplete } from '@mui/material';
import SelectBox from '../../../components/common/SelectBox.jsx';
import Sidebar from '../../../components/sidebar/Sidebar';
import NavBar from '../../../components/navbar/NavBar';
import { config } from '../../../config/Constants';
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import verifyToken from "../../../utils/tokenUtils.js";
import verifyAdmin from '../../../utils/adminUtils';
import { useNavigate } from 'react-router-dom';
import './accountInformation.css';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import Axios from 'axios';

const AccountInformation = () => {
  const navigate = useNavigate();
  const getAuthToken = () => { return Cookies.get('gtms-cp-auth'); };

  const [actualCompany, setActualCompany] = useState();
  const [isActive, setIsActive] = useState(0);
  const [isAdmin, setIsAdmin] = useState(0);
  const [username, setUsername] = useState();
  const [customerAccountId, setCustomerAccountId] = useState();
  const [userAccounts, setUserAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');

  const [roleList, setRoleList] = useState(['TEST']);
  const [role, setRole] = useState(null);

  const selectRole = { label: 'Role?', menuItems: roleList}
  const handleRoleSelectValue = (value) => { setRole(value) }

  const getRoleList = async() => {
    const response = await Axios.get(config.url.GET_ROLE_LIST);
    const list = response.data.roleList.map(obj => obj.ROLE_NAME);
    setRoleList(list);
  }

  const getAccounts = async() => {
    const response = await Axios.get(config.url.GET_USER_ACCOUNTS);
    setUserAccounts(response.data.userAccounts);
  };

  const handleSelectValue = (option) => {
    setSelectedAccount(option || '');
  };

  useEffect(() => {
    const token = getAuthToken();
    token ? verifyToken(token) : navigate('/');
    getAccounts();
    verifyAdmin(navigate);
    getRoleList();
  }, [])

  const handleButtonClick = async(field, value) => {
    verifyAdmin(navigate);
    const account = selectedAccount.USERNAME;
    let record = { [field]: value, criteriaValue: account };
    const isSuccess = await updateField(record);
    notifyUpdated(isSuccess);
  };

  const updateField = async(record) => {
    record.whereField = 'USERNAME';
    record.tableId = 0;
    const response = await Axios.post(config.url.ACCOUNT_UPDATE_FIELD, {
      record: record
    });
    return response.data.isSuccess;
  };

  const notifyUpdated = (isSuccess) => {
    const icon = isSuccess == true ? 'success' : 'error';
    const title = isSuccess == true ? 'Updated Value' : 'Error: Unable to update';

    Swal.fire({
      icon,
      title,
      confirmButtonColor: '#0e60ed',
    });
  }

  return (
  <div className="LandingPage">
    <div className="LandingPageContainer">
      <NavBar/>
      <Sidebar/>
      <div className="AccountInformationPageContent">
        <div className="AccountInformationTop">
          <div className="AccountInformationTitle">
            <h1>User Account Information</h1>
          </div>
        </div>
        <div className="AccountInformationMid">
        <div className="row">
          <div className="accountInformationField">
            <Autocomplete disablePortal id="accountInformationUser"
              getOptionLabel={(userAccounts) => String(userAccounts.USERNAME)}
              sx={{ m: 1, minWidth: 200 }} options={userAccounts}
              renderInput={(params) => <TextField {...params} label="Select Account"/>}
              onChange={(event, selectedAccount) => handleSelectValue(selectedAccount)}
              value={selectedAccount === '' ? null : selectedAccount}
            />
          </div>
        </div>
        <div className="dottedLineBreak"></div>
          <div className="row">
            <div className="accountInformationField">
              <TextField size="small"  id="actualCompany"  label="Actual Company" 
                variant="outlined" disabled= {!selectedAccount}
                onChange={e => setActualCompany(e.target.value)}/>
            </div>
            <div className="accountInformationUpdate">
              <Button  variant="contained" size="small"disabled= {!selectedAccount}
                onClick={() => handleButtonClick('actualCompany', actualCompany)}>
                Update
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="accountInformationSelect">
              <div className="accountInformationSelectBox">
                <SelectBox selectValue={handleRoleSelectValue} selectObject={selectRole} style={{ minWidth: '200px' }}/>
              </div>
            </div>
            <div className="accountInformationUpdate">
              <Button  variant="contained"  size="small"  disabled= {!selectedAccount}
                onClick={() => handleButtonClick('role', role)}>
                Update
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="accountInformationSelect">
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="is-active-label">Is Active?</InputLabel>
                <Select labelId="is-active-label" label="Is Active?" value= {isActive}
                  disabled= {!selectedAccount} onChange={e => setIsActive(e.target.value)}>
                  <MenuItem value={0}>No</MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="accountInformationUpdate">
              <Button  variant="contained"  size="small"   disabled= {!selectedAccount}
                onClick={() => handleButtonClick('isActive', isActive)}>
                Update
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="accountInformationSelect">
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="is-admin-label">Is Admin?</InputLabel>
                <Select labelId="is-admin-label" label="Is Admin?" value={isAdmin}
                  disabled= {!selectedAccount} onChange={e => setIsAdmin(e.target.value)}>
                  <MenuItem value={0}>No</MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="accountInformationUpdate">
              <Button variant="contained"  size="small" disabled= {!selectedAccount}
                onClick={() => handleButtonClick('isAdmin', isAdmin)}>
                  Update
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="accountInformationField">
              <TextField size="small" id="AccountInformationUsername"  label="Username"
                variant="outlined" disabled= {!selectedAccount}
                onChange={e => setUsername(e.target.value)}/>
            </div>
            <div className="accountInformationUpdate">
              <Button variant="contained" size="small" disabled= {!selectedAccount}
                onClick={() => handleButtonClick('accountInformationUsername', username)}>
                Update
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="accountInformationField">
              <TextField size="small" id="AccountInformationCustomerAccountId"
                label="Customer Account ID" variant="outlined" disabled= {!selectedAccount}
                onChange={e => setCustomerAccountId(e.target.value)}/>
            </div>
            <div className="accountInformationUpdate">
              <Button variant="contained"  size="small" disabled= {!selectedAccount}
                onClick={() => handleButtonClick('AccountInformationCustomerAccountId', customerAccountId)}>
                Update
              </Button>
            </div>
          </div>
        </div>
        <div className="AccountInformationBottom"></div>
      </div>
    </div>
  </div>
  )
}

export default AccountInformation