import CustomizedTitle from '../../../components/customizedTitle/CustomizedTitle2';
import ButtonController from '../../../components/containers/buttonController.js';
import ChartController from '../../../components/containers/chartController';
import homepageComponents from '../../../utils/componentsMap.json';
import usePageView from '../../../hooks/analytics/usePageView.js';
import Widget from '../../../components/common/widget/Widget';
import Sidebar from '../../../components/sidebar/Sidebar';
import NavBar from '../../../components/navbar/NavBar';
import verifyToken from "../../../utils/tokenUtils.js";
import React, { useEffect, useState } from 'react';
import { useNavigate} from 'react-router-dom';
import Cookies from 'js-cookie';
import "./homepage.css";

const HomePage = () => {
  const navigate = useNavigate();
  usePageView();

  const pageId = 5;
  const [componentIds, setComponentIds] = useState();
  const [name, setName] = useState('');
  const titleObject = {title: `Welcome ${name}!`};

  const getAuthToken = () => { return Cookies.get('gtms-cp-auth'); };
  const getCompany = () => { return Cookies.get('cp-cn'); };
  const getUsername = () => { return Cookies.get('cp-un'); };
  const getRole = () => { return Cookies.get('cp-role') };

  useEffect(() => {
    const token = getAuthToken();
    const role = getRole();
    if (role == 'VENDOR') { navigate('/formslanding') }
    token ? verifyToken(token) : navigate('/');
    getUserInfo();
    getComponents();
  }, []);

  const getComponents = () => {
    const companyName = getCompany();
    const displayComponents = homepageComponents[companyName];
    setComponentIds(displayComponents)
  }

  const getUserInfo = () => {
    const userName = getUsername();
    setName(userName);
  }

  return (
  <div className='homepage'>
    <div className="homepageContainer">
      <NavBar/>
      <Sidebar/>
      <div className="homepageContent"> 
      <div className="homepageTop">
        <CustomizedTitle titleObject={titleObject}/>
      </div>
      <div className="homepageMid">
        <div className='widgets'>
          {componentIds && componentIds.widgets.length > 0 ? (
            <>
            <div className="widgetContainer">
              {componentIds.widgets.map((widgetId) => (
                <div className="widgetBox">
                  <Widget type={{widgetId: widgetId}}/>
                </div>
              ))}
            </div>
            </>
          ): ( <div></div> )}
        </div>
        <div className='badgeBox'>
          <ChartController chartId={10} />
        </div>
        <div className="components">
          {componentIds && componentIds.charts.length > 0 ? (
            <>
            <div className="flexContainer">
              {componentIds.charts.map((chartId) => (
                <div className="chartBox" key={`chart-${chartId}`}>
                  <ChartController chartId={chartId}/>
                  <ButtonController chartId={chartId}/>
                </div>
              ))}
            </div>
          </>
          ) : (
            <div className="chartBox" key={`chart-0`}>
              <p>Welcome to your dashboard. There are no components available.</p>
              <p>Please contact GTMS to specify what you want shown here.</p>
            </div>
          )}
        </div>
      </div>
      <div className="homepageBottom"></div>
      </div>
    </div>
  </div>
  )
}

export default HomePage