// usePoDetailsHandlers.js
export const usePoDetailsHandlers = (formData, setFormData, poDetailsTemplate) => {
  const addNewEntry = () => {
    setFormData(prevState => ({
      ...prevState,
      poDetails: [...prevState.poDetails, { ...poDetailsTemplate }]
    }));
  };

  const handleChangeDetails = (index, field, value) => {
    const updatedPoDetails = [...formData.poDetails];
    updatedPoDetails[index][field] = value;
    setFormData({ ...formData, poDetails: updatedPoDetails });
  };

  return { addNewEntry, handleChangeDetails };
};