import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';

const DateField = ({setDate, dateObject}) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [label, setLabel] = useState('Set Date');

    const handleDateChange = (date) => {
      setDate(date);
      setSelectedDate(date);
    };

    useEffect(() => {
      if (dateObject && dateObject.label) {
        setLabel(dateObject.label);
      }
    }, [dateObject]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
      label={label}
      value={selectedDate}
      onChange={handleDateChange}
      renderInput={(params) => <TextField {...params} />}
    />
  </LocalizationProvider>
  )
}

export default DateField