import React from 'react'

const PurpleTranTitle = ({title}) => {
  return (
    <>
    <span style={{ color: 'purple' }}>Purple Transportation </span>
    <span style={{ color: 'black' }}>
      {title.substring(title.indexOf(' ', title.indexOf(' ') + 1) + 1)}
    </span>
  </>
  )
}

export default PurpleTranTitle