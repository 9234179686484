import { popUpFormMessage, showSubmittingNotification, closeSubmittingNotification, showMissingFileNotification } from '../../../../utils/components/popup';
import useBiovationForm from '../../../../hooks/forms/biovations/useBiovationForm';
import { Stepper, Step, StepLabel, Button } from '@material-ui/core';
import usePageView from '../../../../hooks/analytics/usePageView';
import Sidebar from '../../../../components/sidebar/Sidebar';
import { getSelectData } from '../../../../utils/api/data';
import NavBar from '../../../../components/navbar/NavBar';
import verifyToken from '../../../../utils/tokenUtils';
import { config } from '../../../../config/Constants';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/biovationsDomestic.css';
import Cookies from 'js-cookie';
import Axios from 'axios';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import StepSix from './StepSix';
import StepSeven from './StepSeven';
import StepEight from './StepEight';
import { v4 as uuidv4 } from 'uuid';

const STEPS = [
  'Requesting Company', 'Origin Information', 'Origin Location', 'Delivery Information',
  'Delivery Location', 'Accessorial', 'PO Details', 'Notes & File Upload'
];

const BiovationsDomestic = () => {
  usePageView();
  const navigate = useNavigate();
  const getAuthToken = () => { return Cookies.get('gtms-cp-auth'); };
  const { customerLogo } = useBiovationForm();
  const [formId, setFormId] = useState(null);

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    formid: formId, stepOneData: '', stepTwoData: '', stepThreeData: '', stepFourData: '', 
    stepFiveData: '', stepSixData: '', stepSevenData: '', stepEightData: '', fileDrop: ''
  });

  const [isStepValid, setIsStepValid] = useState({
    step1Valid: false, step2Valid: false, step3Valid: false, step4Valid: false, 
    step5Valid: false, step6Valid: true, step7Valid: false, step8Valid: false
  });

  useEffect(() => {
    const token = getAuthToken();
    if (Cookies.get('cp-cn') !== 'Biovation Labs') {
      navigate('/')
    }
    token ? verifyToken(token) : navigate('/');
  }, []);

  const createFormId = () => {
    const newUniqueId = uuidv4();
    const truncatedUniqueId = newUniqueId.substring(0, 13);
    const biovationFormId = `BIOV-${truncatedUniqueId}`;
    setFormId(biovationFormId);

    setFormData(prevFormData => ({
      ...prevFormData,
      formid: biovationFormId
    }));
  };

  useEffect(() => {
    createFormId()
  }, [])

  const handleStepOneChange = (data) => {
    const isValid = data.companyName && data.companyContact  && data.companyEmail && data.reference1;
    setFormData({ ...formData, stepOneData: data });
    setIsStepValid({ ...isStepValid, step1Valid: isValid });
  };
  
  let handleStepTwoChange = (data) => { 
    const isValid = data.originName && data.originAddress1  && data.originContact && data.originPhone  && data.originEmail
    && data.openTime && data.closeTime && data.pickupNumber;
    setFormData({ ...formData, stepTwoData: data });
    setIsStepValid({ ...isStepValid, step2Valid: isValid });  
  };

  let handleStepThreeChange = (data) => { 
    const isValid = data.originCountry  && data.originState  && data.originCity && data.originPostal;
    setFormData({ ...formData, stepThreeData: data });
    setIsStepValid({ ...isStepValid, step3Valid: isValid });  
  };

  let handleStepFourChange = (data) => { 
    const isValid = data.consigneeName && data.consigneeAddress1 && data.consigneeContact 
    && data.consigneePhone && data.consigneeEmail && data.consigneeOpenTime && data.consigneeCloseTime && data.deliveryNumber;
    setFormData({ ...formData, stepFourData: data });
    setIsStepValid({ ...isStepValid, step4Valid: isValid });  
  };

  let handleStepFiveChange = (data) => { 
    const isValid = data.deliveryCountry  && data.deliveryState  && data.deliveryCity && data.deliveryPostal;
    setFormData({ ...formData, stepFiveData: data });
    setIsStepValid({ ...isStepValid, step5Valid: isValid });  
  };

  let handleStepSixChange = (data) => { setFormData({ ...formData, stepSixData: data });};

  let handleStepSevenChange = (data) => { 
    let isValid = false;
    const requiredKeys = ['palletNumber', 'pieces', 'sku', 'description', 'poNumber', 'length', 'width', 'height', 'weight']
    for (const key in data) {
      const item = data[key];
      if (requiredKeys.every(key => key in item)) {
        isValid = true;
      }
    }
    setFormData({ ...formData, stepSevenData: data });
    setIsStepValid({ ...isStepValid, step7Valid: isValid });  
  };

  let handleStepEightChange = (data) => {
    const isValid = data.pickUpDate ? true : false;
    setFormData({ ...formData, stepEightData: data });
    setIsStepValid({ ...isStepValid, step8Valid: isValid });  
  };

  let handleFileUpload = (data) => { 
    setFormData({ ...formData, fileDrop: data });
  };

  const renderStep = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <StepOne formData={formData.stepOneData} onChange={handleStepOneChange}/>;
      case 1:
        return <StepTwo formData={formData.stepTwoData} onChange={handleStepTwoChange}/>;
      case 2:
        return <StepThree formData={formData.stepThreeData} onChange={handleStepThreeChange} />;
      case 3:
        return <StepFour formData={formData.stepFourData} onChange={handleStepFourChange}/>;
      case 4:
        return <StepFive formData={formData.stepFiveData} onChange={handleStepFiveChange} />;
      case 5:
        return <StepSix formData={formData.stepSixData} onChange={handleStepSixChange} />;
      case 6:
        return <StepSeven formData={formData.stepSevenData} onChange={handleStepSevenChange}/>;
      case 7:
        return <StepEight formData={formData.stepEightData} onChange={handleStepEightChange} onFileUpload={handleFileUpload}/>;
      default:
        return <div>Step {stepIndex + 1}</div>;
    }
  };

    const getCount = async() => {
      const data = await getSelectData({
        selectValues: 'COUNT(REQUESTING_COMPANY_REFERENCE_NUMBER_1) AS PO_COUNT', 
        tableName: 'BookingRequest.BIOVATION.DOMESTIC_HEADERS_STAGING',
        whereField: 'REQUESTING_COMPANY_REFERENCE_NUMBER_1', 
        whereValue: formData.stepOneData.reference1
      });
      return data[0].PO_COUNT;
    }

  const handleNext = async() => {
    const currentStepValidKey = `step${activeStep + 1}Valid`;
    if (activeStep === STEPS.length - 1) {
      if (isStepValid[currentStepValidKey] === true) {
        handleSubmit();
      } else {
        alert("Please Input Pickup Up Date");
      }
    } else if (isStepValid[currentStepValidKey]) {
      if (activeStep === 0) {
        const count = await getCount();
        if (count > 0) {
          alert("The PO Number you are using has been previously submitted, please email the team at biovations@gtms.us if you are trying to update or add to a shipment.");
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      alert("Please fill out all required fields before proceeding.");
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const checkFile = async() => {
    if (formData?.fileDrop[0]?.file == undefined) {
      return await showMissingFileNotification();
    } else {
      return { isConfirmed: true };
    }
  }

  const tempUpload = async(file) => {
    const formFiles = new FormData();
    formFiles.append('files', file);

    const fileResponse = await Axios.post(
      config.url.TEMP_FILE_UPLOAD, formFiles, {
          headers: { 'Content-Type': 'multipart/form-data'}
        }
    )
    return fileResponse.data.fileInfo;
  }

  const processFileDrop = async (formData, fileArray) => {
    formData.fileDrop = await Promise.all(
      formData.fileDrop.map(async (dropFile) => {
        let matchedFileData;
  
        for (const data of fileArray) {
          for (const file of data) {
            if (dropFile.file && dropFile.file.name) {
              if (file.originalName === dropFile.file.name) {
                matchedFileData = file;
                break;
              }
            }
          }
          if (matchedFileData) break; 
        }
        return { ...dropFile, fileNames: matchedFileData, };
      })
    );
  };

  const handleSubmit = async() => {
    const result = await checkFile();
      if (result.isConfirmed) {        
        showSubmittingNotification();

        let fileArray = []
        formData.fileDrop = formData.fileDrop.filter(item => item.file);
        for (const item of formData.fileDrop) {
            if (item.file) {
              const fileInfo = await tempUpload(item.file);
              fileArray.push(fileInfo);
            }
        }
        
        await processFileDrop(formData, fileArray);

        const response = await Axios.post(
          config.url.SUBMIT_BIOVATIONS_DOMESTIC_FORM, {
            formData: formData,
            userName: Cookies.get('cp-un'),
          }
        );

        closeSubmittingNotification();
        popUpFormMessage(response.data.success);
      }  else {
      closeSubmittingNotification();
    } 
  }

  return (
    <div className='LandingPage'>
      <div className="LandingPageContainer">
        <NavBar/>
        <Sidebar/>
        <div className="BiovationsDomesticPageContent">
          <div className="BiovationsDomesticForm">
            <div className="BiovationsDomesticFormTop">
              <div className="BiovationDomesticTitle">
                <h1 style={{textAlign: 'center'}}>Biovation Labs Domestic Form</h1>
                <h3 style={{textAlign: 'center'}}>Form ID: {formId}</h3>
              </div>
              <div className="BivoationDomesticLogo">
                {customerLogo && <img src={customerLogo} alt="Company Logo" style={{height: '50px'}}/>}
              </div>
            </div>
            <div className="BiovationsStepper">
              <Stepper activeStep={activeStep}>
                {STEPS.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div className="Biovationsformsubtitle">
                <h3>{STEPS[activeStep]} fields</h3>
              </div>
              <div className="Biovationsformcontent">
                {renderStep(activeStep)}
              </div>
              <div className="BiovationsButtons">
                <div className="BioviationsDomesticBack">
                  <Button variant="outlined" disabled={activeStep === 0} onClick={handleBack}>
                    Back
                  </Button>
                </div>
                <div className="BiovationsSubmit">
                  <Button variant="contained" color="primary" onClick={handleNext}>
                    {activeStep === STEPS.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BiovationsDomestic;