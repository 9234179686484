import DynamicTable from '../../../components/tables/dynamicTable';
import usePageView from '../../../hooks/analytics/usePageView';
import Sidebar from '../../../components/sidebar/Sidebar';
import NavBar from '../../../components/navbar/NavBar';
import { config } from '../../../config/Constants';
import React, { useEffect, useState } from 'react';
import FileDownload from 'js-file-download';
import { Button } from '@mui/material';
import ReactGA from 'react-ga4';
import Cookies from 'js-cookie';
import Papa from 'papaparse';
import './serviceStats.css';
import Axios from 'axios';

const ServiceStats = () => {
  usePageView();
  const [serviceStats, setServiceStats] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => { getStatsData(); }, []);

  const getStatsData = async() => {
    const response = await Axios.get(config.url.GET_REPORT, {
      params: {
        reportName: 'servicestats',
        companyName: Cookies.get('cp-cn')
      }
    });
    const dataObject = {data: response.data.report, size: 'small'};
    setLoading(false);
    setServiceStats(dataObject);
  }

  const handleDownload = () => {
    ReactGA.event({ category: 'Button', action: 'Click', label: 'Service Stats Download Button', value: 1 });
    const csv = Papa.unparse(serviceStats);
    FileDownload(csv, `${Cookies.get('cp-cn')}-Service-Stats.csv`);
  }

  return (
    <div className="serviceStatsPage">
      <div className="LandingPageContainer">
        <NavBar/>
        <Sidebar/>
        <div className="serviceStatsPageContent">
          <div className="serviceStatsTitle">
            <h1>Service Statistics</h1>
          </div>
          <div className="reportSpace">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div className="serviceStatsReport">
                <DynamicTable dataObject={serviceStats} />
              </div>
            )}
            <div className="downloadStatsReport">
              <Button variant='outlined' sx={{ width: '200px' }} onClick={handleDownload}>
                Download Report
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceStats