import { config } from "../config/Constants.js";
import Axios from 'axios';

const verifyToken = async(token) => {
    const response = await Axios.post(config.url.VERIFY_TOKEN, { token: token, });

    if (response.data.isVerified === false) {
        window.location.href = '/';
    }
}

export default verifyToken;