import useDestinationData from '../../../../hooks/forms/biovations/useDestinationData';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import React, { useState } from 'react';

const StepFour = ({ formData, onChange }) => {
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const { localFormData, handleChange } = useDestinationData(formData, onChange)
  
    const handleCheckboxChange = (event) => {
      setIsCheckboxChecked(event.target.checked);
    };

  return (
    <div className="BiovationDomStepEight">
      <div className="BiovationFormContainer">
        <div className="BiovationFormFieldsStep2">
          <div className="BiovationColumn">
            <div className="BiovationDomField">
              <TextField size="small" id="consigneeName" label="Consignee Name" 
                variant="outlined" value={localFormData.consigneeName || ''} 
                onChange={handleChange} required
              />
            </div>
            <div className="BiovationDomField">
              <TextField  size="small" id="consigneeAddress1" label="Consignee Address 1" 
              variant="outlined" value={localFormData.consigneeAddress1 || ''} 
              onChange={handleChange} required
              />
            </div>
            <div className="BiovationDomField">
              <TextField size="small" id="consigneeAddress2" label="Consignee Address 2" 
              variant="outlined" value={localFormData.consigneeAddress2 || ''} 
              onChange={handleChange}
              />
            </div>
            <div className="BiovationDomField">
              <TextField size="small" id="consigneeContact" label="Consignee Contact" 
              variant="outlined" value={localFormData.consigneeContact || ''} 
              onChange={handleChange} required
              />
            </div>
            <div className="BiovationDomField">
              <TextField size="small" id="consigneePhone"label="Consignee Phone" 
              variant="outlined" value={localFormData.consigneePhone || ''} 
              onChange={handleChange} required
              />
            </div>
          </div>
          <div className="BiovationColumn">
            <div className="BiovationDomField">
              <TextField size="small" id="consigneeEmail" label="Consignee Email" 
              variant="outlined" value={localFormData.consigneeEmail || ''} 
              onChange={handleChange} required
              />
            </div>
            <div className="BiovationDomField">
              <TextField  size="small" id="consigneeOpenTime" label="Open Time" 
                variant="outlined" value={formData.consigneeOpenTime  || ''} onChange={handleChange} 
                required
              />
            </div>
            <div className="BiovationDomField">
              <TextField  size="small" id="consigneeCloseTime" label="Close Time" 
                variant="outlined" value={formData.consigneeCloseTime  || ''} onChange={handleChange} 
                required
              />
            </div>
            <div className="BiovationDomField">
              <TextField  size="small" id="deliveryNumber" label="Delivery Number" 
                variant="outlined" value={formData.deliveryNumber  || ''} onChange={handleChange} 
                required
              />
            </div>
            <div className="BiovationFirstServe">
              <label>
                <Checkbox checked={isCheckboxChecked} onChange={handleCheckboxChange}/>
                Is dock first come first serve?
              </label>
              <TextField
                size="small" id="deliveryDockFirstServe" label="Include all delivery information here" 
                variant="outlined" value={formData.deliveryDockFirstServe  || ''} onChange={handleChange} 
                disabled={!isCheckboxChecked} 
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StepFour