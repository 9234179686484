import React, {useEffect} from 'react'

const useAutoCompleteFormData = (setFormData, fieldName, data) => {
    useEffect(() => {
        if (data) {
          setFormData(prevFormData => ({
            ...prevFormData,
            [fieldName]: data
          }));
        }
      }, [data, setFormData, fieldName]);
}

export default useAutoCompleteFormData