import Swal from 'sweetalert2';

export const popUpFormMessage = (isSuccess) => {
  const icon = isSuccess ? 'success' : 'error';
  const title = isSuccess ? 'Form Submitted Successfully' : 'Form Could not be processed.';

  Swal.fire({
    icon: icon,
    title: title,
    confirmButtonColor: '#1769aa',
    allowOutsideClick: false,
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.reload();
    }
  })
}

export const showMissingFileNotification =() => {
  return Swal.fire({
    title: 'Missing File in Submission',
    text: "You can either continue or go back.",
    icon: 'warning',
    confirmButtonColor: '#1769aa',
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonText: 'Continue',
    cancelButtonText: 'Go Back'
  })
}

let submittingNotification;
export const showSubmittingNotification = () => {
  submittingNotification = Swal.fire({
    title: 'Submitting Form...',
    allowOutsideClick: false,
    showConfirmButton: false,
    willOpen: () => { Swal.showLoading(); }
  });
};

export const closeSubmittingNotification = () => {
  if (submittingNotification) {
    submittingNotification.close();
  }
};

