import usePageView from "../../../hooks/analytics/usePageView.js";
import { config } from '../../../config/Constants.js';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import Swal from 'sweetalert2';
import Axios from 'axios';
import "./register.css";

const Register = () => {
  usePageView();
  const navigate = useNavigate();
  const pageId = 2;
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [userName, setUserName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();
  const [company, setCompany] = useState();
  const [isVendor, setIsVendor] = useState(false);
  const [vendorName, setVendorName] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const submit = async() => {
    ReactGA.event({ category: 'Button', action: 'Click', label: 'Register Button', value: 1 });
    try {
      const response = await Axios.post(config.url.USER_REGISTER, {
        firstName : firstName,
        lastName : lastName,
        username : userName,
        email : email,
        password : password,
        passwordConfirm : passwordConfirm,
        company : company,
        isAdmin : isAdmin,
        vendorName : vendorName
      })

      if (response.status == 201) {
        handleSuccess(response);
      }
    } catch(error) {
        if (error.response.status == 400) {
          handleError(error);
        }
    };
  };

  const handleSuccess = (response) => {
    Swal.fire({
      icon: 'success',
      title: 'Registeration Successful',
      text: response.data.message,
    })
  };

  const handleError = (error) => {
    Swal.fire({
      icon: 'error',
      title: 'Registeration Unsuccessful',
      text: error.response.data.message,
    });
  };

  const handleVendorCheckboxChange = (event) => {
    setIsVendor(event.target.checked);
  };

  const handleCheckboxChange = (event) => {
    setIsAdmin(event.target.checked);
  };

  const back = () => {
    navigate('/');
  }

  return (
    <div className="registerPage">
      <div className="registerContainer">
        <div className="registerTop">
          <div className="registerTitle">
            <h1>Create Account</h1>
          </div>
        </div>
        <div className="registerMid">
          <div className="registerRow">
            <div className="registerFirstName">
              <TextField id="outlined-basic" label="First Name" size="small"
                variant="standard" onChange={e => setFirstName(e.target.value)}
              />
            </div>
            <div className="registerLastName">
              <TextField id="outlined-basic" label="Last Name" size="small"
                variant="standard" onChange={e => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div className="registerRow">
            <div className="registerUsername">
              <TextField  id="outlined-basic" label="Username" size="small"
                variant="standard" onChange={e => setUserName(e.target.value)}
              />
            </div>
            <div className="registerEmail">
              <TextField id="outlined-basic" label="Email" size="small"
                variant="standard" onChange={e => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="registerRow">
            <div className="registerPasswordOne">
              <TextField id="outlined-basic" label="Password" type="password"
                size="small" variant="standard" onChange={e => setPassword(e.target.value)}
              />
            </div>
            <div className="registerPasswordTwo">
              <TextField id="outlined-basic" label="Confirm Password" type="password"
                size="small" variant="standard" onChange={e => setPasswordConfirm(e.target.value)}
              />
            </div>
          </div>
          <div className="registerRow2">
            <div className="registerCompany">
              <TextField id="outlined-basic" label="Company Name" size="small"
                variant="standard"onChange={e => setCompany(e.target.value)}
              />
            </div>
            <div className="registerAdmin">
              <Checkbox checked={isAdmin} onChange={handleCheckboxChange} color="primary"/>
              <Typography variant="body1" component="label" sx={{ ml: 1 }}>
                  Admin Account?
              </Typography>
            </div>
          </div>
          <div className="registerRow">
            <div className="registerVendor">
              <Checkbox checked={isVendor} onChange={handleVendorCheckboxChange} color="primary"/>
              <Typography variant="body1" component="label" sx={{ ml: 1 }}>
                Are you a Vendor?
              </Typography>
              </div>
              <div className="vendorField">
                {isVendor &&(
                  <TextField id="outlined-basic" label="Vendor Company" size="small"
                    onChange={e => setVendorName(e.target.value)}
                  />
                )}
            </div>
          </div>
        </div>
        <div className="registerBottom">
          <div className="registerSubmit">
            <Button variant="contained" onClick={submit}>Submit</Button>
          </div>
          <div className="registerback" onClick={back}>
            <Button variant="outlined">Back</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register