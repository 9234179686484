import { useEffect, useState } from "react"
import { getSelectData } from '../../../utils/api/data';


const useFetchVendorName = (username) => {
    const [vendorName, setVendorName] = useState(null);

    useEffect(() => {
        const getVendorName = async() => {
            const data = await getSelectData({
                selectValues:  'VENDOR_NAME',
                tableName: 'CustomerPortal.dbo.ACCOUNT',
                whereField: 'USERNAME',
                whereValue: username,
              });
              setVendorName(data);
              
        }
        getVendorName();
    }, [])

    return vendorName
}

export default useFetchVendorName;
