import { getSelectData } from "../api/data";
import Cookies from 'js-cookie';

export const getDownload = async(chartId) => {
    switch(chartId) {
        case 13: {
            const multipleOffender = await getSelectData({
                selectValues: '*', 
                tableName: 'CUSTOMER_PORTAL.REPORT.ADDRESS_CORRECTION_MULTIPLE_OFFENDER_DOWNLOAD',
                whereField: 'Customer', whereValue: Cookies.get('cp-cn'), db: 'SF'
            });
        
            const details = await getSelectData({
                selectValues: '*', tableName: 'CUSTOMER_PORTAL.REPORT.ADDRESS_CORRECTION_DETAILS',
                whereField: 'Customer', whereValue: Cookies.get('cp-cn'), db: 'SF'
            });

            return [{ 
                'Multiple Offender Summary': multipleOffender, 
                'Address Correction Detail': details
            }, {
                'name': 'Address Corrections'
            }]
        } 
        default:
          return 'error';
    }
}