import { Stepper, Step, StepLabel } from '@material-ui/core';
import React from 'react';

const CustomStepper = ({ steps, activeStep }) => {
  return (
    <Stepper activeStep={activeStep}>
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
      </Stepper>
  )
}

export default CustomStepper