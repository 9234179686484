import Dropbox from '../../../../components/files/dropbox/Dropbox';
import SelectBox from '../../../../components/common/SelectBox';
import usePageView from '../../../../hooks/analytics/usePageView';
import { config } from '../../../../config/Constants';
import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
import './csvbooking.css';
import Axios from 'axios';

const CsvBooking = () => {
  usePageView();
  const [selectValue, setSelectValue] = useState(null);
  const [fileInfo, setFileInfo] = useState(null);
  const [email, setEmail] = useState(null);
  const [contact, setContact] = useState(null);

  const selectObject = {
    label: 'Forms',
    menuItems: ['Domestic', 'International']
  }

  const handleSelectValue = (selectedValue) => {
    setSelectValue(selectedValue);
  }

  const handleDownload = async() => {
    let fileURL;
    if (selectValue === 'Domestic') {
      fileURL = '/templates/domestic_template.csv';
    } else if (selectValue === 'International') {
      fileURL = '/templates/international_template.csv';
    } else {
      Swal.fire({
        title: 'Please Select a form Type',
        confirmButtonColor: '#1769aa',
      })
    }

    if (fileURL) {
      try {
        const response = await fetch(fileURL);
        const blob = await response.blob();
        saveAs(blob, `${selectValue}_Template.csv`);
      } catch (error) {
        console.log('Error');
      }
    };
  }

  const handleSubmit =async () => {
    if (selectValue === null || email === null || contact === null) {
      alert('Fields can\'t be Null, please provide values and submit again');
    } else {
      const isSuccess = await submitForm();
      popUpMessage(isSuccess);
    }
  }

  const submitForm = async() => {
    const response = await Axios.post(config.url.SUBMIT_CSV_FORM, {
      formType: selectValue,
      fileInfo: fileInfo,
      email: email,
      contact: contact
    });
    return response.data.success;
  }

  const popUpMessage = (isSuccess) => {
    const icon = isSuccess ? 'success' : 'error';
    const title = isSuccess ? 'Form Submitted Successfully' : 'File Could not be processed.';

    Swal.fire({
      icon: icon,
      title: title,
      confirmButtonColor: '#1769aa',
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    })
  }

  return (
    <div className="csvBookingPage">
      <div className="csvBookingContainer">
        <div className="csvBookingTop"></div>
        <div className="csvBookingMid">
          <div className="csvBookingForm">
            <div className="csvBookingFormTop">
              <div className="formTitle">
                <h1>CSV Booking Form</h1>
              </div>
            </div>
            <div className="csvBookingFormMid">
              <div className="csvBookingChoosingForm">
                <div className="formRow">
                  <div className="csvFormColumn">
                    <div className="innerFormRow">
                      <div className="csvBookingFormLabel">
                        <h4>Contact Name:</h4>
                      </div>
                      <div className="csvBookingFormInput">
                        <TextField
                          onChange={e => setContact(e.target.value)}
                          size="small"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="csvFormColumn">
                    <div className="innerFormRow">
                      <div className="csvBookingFormLabel">
                        <h4>Email:</h4>
                      </div>
                      <div className="csvBookingFormInput">
                        <TextField 
                          onChange={e => setEmail(e.target.value)}
                          size="small"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="formRow">
                  <div className="csvBookingFormLabel">
                    <h4>Form Type:</h4>
                  </div>
                  <div className="csvBookingSelect">
                    <SelectBox selectValue={handleSelectValue} selectObject={selectObject}/>
                  </div>
                </div>
               <div className="formRow">
                <div className="csvBookingFormLabel">
                    <h3>Need Template?</h3>
                  </div>
                  <div className="csvBookingDownload">
                    <Button onClick={handleDownload}>Download</Button>
                  </div>
               </div>
              </div>
              <div className="csvBookingFormSubmit">
                <div className="csvBookingDropzone">
                  <Dropbox setFileInfo={setFileInfo} />
                </div>
              </div>
            </div>
            <div className="csvBookingFormBottom">
              <div className="csvBookingSubmitForm">
                <Button onClick={handleSubmit} variant='contained'>Submit</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="csvBookingBottom"></div>
      </div>
    </div>
  )
}

export default CsvBooking