import Axios from 'axios';
import { config } from '../config/Constants';
import Cookies from 'js-cookie';

const verifyAdmin = async (navigate) => {
  const username = getUsername();
  const response = await Axios.get(config.url.VERIFY_ADMIN, {
    params: {
      username: username,
    },
  });
  const isAdmin = response.data.isVerified.IS_ADMIN;
  if (isAdmin !== true) {
    navigate('/unauthorized');
  }
};

const getUsername = () => {
    return Cookies.get('cp-un')
  }

export default verifyAdmin;