import { storeSidebarData } from "../../redux/slices/sidebarSlice";
import ListItemButton from "@mui/material/ListItemButton";
import { useSelector, useDispatch } from 'react-redux';
import ListItemText from "@mui/material/ListItemText";
import { config } from '../../config/Constants';
import { useNavigate } from 'react-router-dom';
import ListItem from "@mui/material/ListItem";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import React, { useEffect } from 'react';
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import Cookies from 'js-cookie';
import Axios from 'axios';

const Sidebar = React.memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const drawerWidth = 200;

  const sidebarData = useSelector((state) => state.sidebar);

  const getUserName = async() => {
    return Cookies.get('cp-un');
  }

  useEffect(() => {
    getPageAccess();
  }, [])

  const getPageAccess = async() => {
    if (sidebarData.sidebarData.length == 0) {
      const userName = await getUserName();
      const response = await Axios.get(config.url.GET_PAGE_ACCESS, {
        params: {
          userName: userName,
        }
      })
      dispatch(storeSidebarData(response.data.page));
    }
  }

  const handleClick = async(text) => {
    switch(text) {
      case 'Home Page':
        navigate('/homepage');
        break;
      case 'Shipment Track':
        navigate('/shipmenttrack');
        break;
      case 'Shipment-Trac':
        navigate('/shipmenttrack');
        break;
      case 'Reports':
        navigate('/reportslanding');
        break;
      case 'Remits':
        navigate('/remits');
        break;
      case 'Tracking':
        navigate('/trackinglanding');
        break;
      case 'Parcel Audit':
        navigate('/auditcenter');
        break;
      case 'Forms':
        navigate('/formslanding');
        break;
      case 'Log Out':
        logout();
        break;
    }
  }

  const logout = () => {
    const cookieNames = ['gtms-cp-auth', 'cp-a', 'cp-csn', 'cp-cn', 'cp-admin', 'cp-ci', 'cp-un'];
    cookieNames.forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
    dispatch(storeSidebarData([]));
    navigate('/');
  }

  return (
    <div className='sidebar'>
      <Box sx={{ display: "flex" }}>
      <Drawer
        sx={{
        width: 200,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box"
        }
        }}
        variant="permanent"
        anchor="left"
      >
      <Toolbar />
    <Divider />
      <List>
        {sidebarData.sidebarData.map((obj, index) => (
          <ListItem key={obj.PAGE_NAME} disablePadding>
            <ListItemButton onClick={() => handleClick(obj.PAGE_NAME)}>
              <ListItemText primary={obj.PAGE_NAME} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {[/*"My Account", "Settings",*/ "Log Out"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={() => handleClick(text)}>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 0 }}
        ></Box>
      </Box>
    </div>
  )
});

export default Sidebar