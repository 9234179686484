import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { Typography } from '@material-ui/core';
import React from 'react';


const TrackingDetails = ({data}) => {
  return (
    <div>
      <div style={{ height: '115px', overflow: 'auto'}}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={3} padding="none">
                <Typography style={{ fontWeight: 'bold' }}>Tracking Details</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell padding='none'>
                <Typography style={{ fontWeight: 'bold' }}>Date</Typography>
              </TableCell>
              <TableCell padding='none'>
                <Typography style={{ fontWeight: 'bold' }}>Code</Typography>
              </TableCell>
              <TableCell padding='none'>
                <Typography style={{ fontWeight: 'bold' }}>Status</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index}>
              <TableCell padding='none'>{item.DATE}</TableCell>
              <TableCell padding='none'>{item.CODE}</TableCell>
              <TableCell padding='none'>{item.STATUS}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default TrackingDetails