import IconController from '../../containers/iconController';
import { config } from '../../../config/Constants';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Axios from 'axios';
import "./widget.css";

const Widget = ({ type }) => {
  const [widgetNumber, setWidgetNumber] = useState('Calculating. . .');
  const [widgetDetails, setWidgetDetails] = useState();
  const [previousWidgetNumber, setPreviousWidgetNumber] = useState(null);

  const widgetStyle = {
    backgroundColor: (widgetDetails && widgetDetails.WIDGET_COLOR) || '#FFFFFF'
  };

  useEffect(() => {
    setWidgetNumber('Calculating. . . ')
    getWidgetDetails(type);
    getWidgetNumber(type);
  }, [type]);

  const getCompany = () => {
    return Cookies.get('cp-cn');
  }

  const getWidgetDetails = async(type) => {
    const response = await Axios.get(config.url.GET_WIDGET_DETAILS, {
      params: {
        widgetId: type.widgetId
      }
    });
    setWidgetDetails(response.data.widgetDetails[0])
  }

  const getWidgetNumber = async(type) => {
    const companyName = getCompany();
    const response = await Axios.get(config.url.GET_WIDGET_DATA, {
      params: {
        widgetId: type.widgetId,
        companyName: companyName,
        carrier: type.carrier,
      }
    })
    setWidgetNumber(response.data.widgetValue.RECORD);
    setPreviousWidgetNumber(response.data.widgetValue.RECORD2);
  }

  return (
    <div className='widget' style={widgetStyle}>
      <div className="widgetPageContent">
        <div className="leftWidget">
          {widgetDetails && (
            <div className="widgetIcon">
              <IconController iconType={widgetDetails.WIDGET_ICON} />
            </div>
          )}
        </div>
        <div className="rightWidget">
          <div className="widgetTop">
            {widgetDetails && (
              <div className="widgetTitle">
                {widgetDetails.WIDGET_NAME}
              </div>
            )}
          </div>
          <div className="widgetMid">
            {widgetNumber && (
              <div className="widgetNumber">
                {widgetNumber}
              </div>
            )}
            {previousWidgetNumber !== null && (
              <div className={`widgetNumber2 ${previousWidgetNumber < 0 ? 'green-text' : 'red-text'}`}>
                {previousWidgetNumber}
              </div>
            )}
          </div>
        </div>
        <div className="widgetBottom">
        </div>
      </div>
    </div>
  );
}

export default Widget;