import React, { useState, useEffect } from 'react';
import { Button, TextField } from '@mui/material';

const EditableDataTable = ({columns, onRowDataChange}) => {
  const [rowData, setRowData] = useState([{}]);

  const addRow = () => {
    setRowData([...rowData, {}]);
  };

  const removeRow = (index) => {
    const updatedRowData = [...rowData];
    updatedRowData.splice(index, 1);
    setRowData(updatedRowData);
  }

  const handleFieldChange = (value, rowIndex, colKey) => {
    const updatedRowData = [...rowData];
    updatedRowData[rowIndex][colKey] = value;
    setRowData(updatedRowData);
  };

  const getRowData = () => {
    return rowData;
  };

  useEffect(() => {
    onRowDataChange(rowData);
  }, [rowData, getRowData]);

  return (
    <div> 
      <table>
        <thead>
          <tr>
            {columns.map((col) => {
              return <th key={col.key}>{col.label}</th>
            })}
          </tr>
        </thead>
          <tbody>
            {rowData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((col) => (
                  <td key={col.key}>
                    <TextField
                      type="text"
                      size="small"
                      value={row[col.key] || ''}
                      onChange={(e) =>
                        handleFieldChange(e.target.value, rowIndex, col.key)
                    }
                    />
                  </td>
                ))}
              <td>
                <Button 
                  style={{ color: 'red' }}
                  onClick={() => removeRow(rowIndex)}>
                  Remove
                </Button>
              </td>
              </tr>
            ))}
          </tbody>
      </table>
      <div className="editableTableAddrow" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px', paddingBottom: '10px' }}>
        <Button 
          variant="outlined" 
          onClick={addRow} style={{ color: 'green' }}>
          Add Row
        </Button>
      </div>
    </div>
  )
}

export default EditableDataTable