import { Button, TextField } from '@material-ui/core';
import SelectBox from '../../../../components/common/SelectBox';
import React, {useState} from 'react';

const PoDetails = ({poDetail, handleChange, addNewEntry}) => {

  const handleKeyDown = (e) => {
    if (!(e.key === '.' || (e.key >= '0' && e.key <= '9') || e.key === 'Backspace' 
    || e.key === 'Delete' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Home' 
    || e.key === 'End' || e.key === 'Tab')) {
      e.preventDefault();
    }
  };

  return (
    <div className="k2jPoDetails">
      <div className="poMessage">
        <div className="poDetailsMessage">
          <p>If the pallet dimensions or weights are NOT the same, please fill out the pallet measurements separately.</p>
        </div>
      </div>
      {poDetail.map((poDetails, index) => (
        <div>
          <p>Enter Detail Fields:</p>
          <div key={index} className="PoDetailsCol">
            <div className="PoDetailsField">
              <TextField 
                size="small" 
                id={`palletNumber-${index}`}
                label="Number of Pallets" 
                variant="outlined" 
                value={poDetails.palletNumber || ''}
                onChange={(e) => handleChange(index, 'palletNumber', e.target.value)}
                onKeyDown={handleKeyDown}
                required
              />
            </div>
            <div className="PoDetailsField">
              <TextField 
                size="small" 
                id={`pieces-${index}`}
                label="Pieces" 
                variant="outlined"
                value={poDetails.pieces || ''}
                onChange={(e) => handleChange(index, 'pieces', e.target.value)}
                onKeyDown={handleKeyDown}
                required
              />
            </div>
            <div className="PoDetailsField">
              <TextField 
                size="small" 
                id={'sku'} 
                label="SKU Number" 
                variant="outlined"
                value={poDetails.sku || ''}
                onChange={(e) => handleChange(index, 'sku', e.target.value)}
                required
              />
            </div>

            <div className="PoDetailsField">
              <TextField 
                size="small" 
                id={'description'} 
                label="Description" 
                variant="outlined"
                value={poDetails.description || ''}
                onChange={(e) => handleChange(index, 'description', e.target.value)}
                required
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
              />
          </div>
          <div className="PoDetailsField">
            <TextField 
              size="small" id={'poNumber'} 
              label="PO Number" 
              variant="outlined"
              value={poDetails.po || ''}
              onChange={(e) => handleChange(index, 'po', e.target.value)}
              required
            />
          </div>
        </div>

        <div className="PoDetailsCol">
          <div className="PoDetailsField">
            <TextField 
              size="small" 
              id={'length'} 
              label="Length" 
              variant="outlined"
              value={poDetails.length || ''}
              onChange={(e) => handleChange(index, 'length', e.target.value)}
              onKeyDown={handleKeyDown}
              required
            />
          </div>
          <div className="PoDetailsField">
            <TextField 
              size="small" 
              id={'width'} 
              label="Width" 
              variant="outlined"
              value={poDetails.width || ''}
              onChange={(e) => handleChange(index, 'width', e.target.value)}
              onKeyDown={handleKeyDown}
              required
            />
          </div>
          <div className="PoDetailsField">
            <TextField 
              size="small" 
              id={'height'} 
              label="Height" 
              variant="outlined"
              value={poDetails.height || ''}
              onChange={(e) => handleChange(index, 'height', e.target.value)}
              onKeyDown={handleKeyDown}
              required
            />
          </div>
          <div className="PoDetailsField">
            <TextField 
              size="small" 
              id={'weight'} 
              label="Total Weight" 
              variant="outlined"
              value={poDetails.weight || ''}
              onChange={(e) => handleChange(index, 'weight', e.target.value)}
              onKeyDown={handleKeyDown}
              required
            />
          </div>
          <div className="PoDetailsField">
            <TextField 
              size="small" 
              id={'nmfc'} 
              label="NMFC" 
              variant="outlined"
              value={poDetails.nmfc || ''}
              onChange={(e) => handleChange(index, 'nmfc', e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        {/*}
        <div className="BiovationCol">
          <div className="PoDetailsField">
            <SelectBox 
              selectValue={handleClassSelectValue} 
              selectObject={selectClass}/>
          </div>
          <div className="PoDetailsField">
            <SelectBox 
              selectValue={handlePackageSelectValue} 
              selectObject={selectPackage}/>
          </div>
        </div>
        **/}
          
        </div>
      ))}
      <div className="PoDetailsAdd">
        <Button onClick={addNewEntry}>Add</Button>
      </div>
    </div>
  )
}

export default PoDetails