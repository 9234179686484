
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const StackedBar = ({chartData}) => {
  return (
    <ResponsiveContainer>
      <BarChart
        data={chartData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="NAME" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="CORRECT" stackId="a" fill="#36454F" />
        <Bar dataKey="INCORRECT" stackId="a" fill="#FF0000" />
      </BarChart>
    </ResponsiveContainer>
  );
}


export default StackedBar
