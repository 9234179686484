import { setChartFilteredData } from "../../../redux/slices/chartfilterSlice";
import { setSummaryData  } from "../../../redux/slices/summarydataSlice";
import filterPanelMap from '../../../utils/filterPanelMap.json';
import { getSelectData } from "../../../utils/api/data";
import { config } from '../../../config/Constants';
import React, { useState, useEffect } from 'react';
import DateField from '../datetime/DateField';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import SelectBox from '../SelectBox';
import Cookies from 'js-cookie';
import './filterPanel.css';
import Axios from 'axios';

const FilterPanel = ({chartId}) => {
  const dispatch = useDispatch();
  const filterOptions = filterPanelMap[chartId];
  const [selectedOptions, setSelectedOptions] = useState({});
  const [accountNumberItems, setAccountNumberItems] = useState([]);

  const getCompany = () => { return Cookies.get('cp-cn'); };

  const handleSelectValue = (option, value) => {
    setSelectedOptions(prevState => ({
      ...prevState, [option]: value
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      const items = await getSelectData({
        selectValues: '"AccountNumber"',
        tableName: 'CUSTOMER_PORTAL.REPORT.ADDRESS_CORRECTION_DETAILS',
        whereField: 'Customer', whereValue: Cookies.get('cp-cn'), db: 'SF'
      });
      const accountNumbers = items.map((item) => item.AccountNumber);
      setAccountNumberItems(accountNumbers);
    };

    fetchData();
  }, [accountNumberItems]);


  const filterComponents = {
    'Mode': (
      <SelectBox 
        selectValue={(value) => handleSelectValue('Mode', value)}
        selectObject={{ label: 'Mode', menuItems: ['Parcel', 'TL', 'LTL'] }} 
      />
    ),
    'Start-Date': (
      <DateField 
        setDate={(value) => handleSelectValue('Start-Date', value)}
        dateObject={{label: 'Start-Date'}}
      />
    ),
    'End-Date': (
      <DateField 
        setDate={(value) => handleSelectValue('End-Date', value)}
        dateObject={{label: 'End-Date'}}
      />
    ),
    'Carrier': (
      <SelectBox 
        selectValue={(value) => handleSelectValue('Carrier', value)}
        selectObject={{ label: 'Carrier', menuItems: ['UPS', 'FedEx'] }} 
    />
    ),
    'Account-Number': (
      <SelectBox 
        selectValue={(value) => handleSelectValue('Account-Number', value)}
        selectObject={{ label: 'Account-Number', menuItems: accountNumberItems }} 
    />
    )
  };

  const getData = async() => {
    const response = await Axios.get(config.url.SEARCH_PANEL_FILTER, {
      params: { 
        chartId: chartId, 
        selectedValues: selectedOptions, 
        companyName: getCompany() }
    });
    const filterData = {chartId: chartId, data: response.data}
    dispatch(setChartFilteredData(filterData));
  };

  const handleSubmit = () => { getData(); }

  return (
    <div className="filter-panel">
      <div className="filter-fields">
        {filterOptions.labels.map((option, index) => (
          <div className='filter-field' key={index}>
            {filterComponents[option] && React.cloneElement(filterComponents[option])}
          </div>
        ))}
      </div>
      <div className="filter-submit">
        <Button variant="outlined" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default FilterPanel