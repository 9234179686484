import TrackingDetails from '../../../components/tables/trackingDetails/TrackingDetails';
import Sidebar from '../../../components/sidebar/Sidebar';
import Map from '../../../components/tracking/map/Map';
import NavBar from '../../../components/navbar/NavBar';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import { config } from '../../../config/Constants';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import './trackingmap.css';
import Axios from 'axios';

const TrackingMap = () => {
  const pageId = 10;
  const [coordinates, setCoordinates] = useState([{LATITUDE: 40.758701, LONGITUDE: -111.876183}])
  const [selectFilter, setSelectFilter] = useState('');
  const [selectValues, setSelectValues] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [trackingDetails, setTrackingDetails] = useState([]);


  const handleSelectFilter = async(event) => {
    setSelectFilter(event.target.value);
    setSelectedOption('');

    Axios.post(config.url.OCEAN_TRACKING_FILTER, {
      value: event.target.value,
      companyName: Cookies.get('cp-cn'),
      userName: Cookies.get('cp-un')
    }).then((response) => {
      setSelectValues(response.data);
    }).catch((error) => {
      Swal.fire({
        title: 'Oops',
        text: 'Please Contact GTMS due to Outage.'
      })
    })
  }

  const handleSelectValue = (option) => {
    if (option) {
      setSelectedOption(option);
    } else {
      setSelectedOption('');
    }
  };

  const submit = () => {
    if (selectedOption === '') {
      Swal.fire({
        title: 'Oops.',
        text: 'Please Select Value to Obtain Tracking Information.',
        confirmButtonColor: '#0e60ed',
      });
    } else {
      Axios.post(config.url.OCEAN_TRACKING_SUBMIT, {
        selectedOption: selectedOption.VALUE,
        selectedFilter: selectFilter,
        userName: Cookies.get('cp-un')
      }).then((response) => {
        setCoordinates(response.data)
      }).catch((error) => {
        Swal.fire({
          title: 'Oops',
          text: 'Please Contact GTMS due to outage.'
        })
      });

      
      Axios.post(config.url.OCEAN_TRACKING_DETAILS, {
        selectedOption: selectedOption.VALUE,
        selectedFilter: selectFilter,
        userName: Cookies.get('cp-un')
      }).then((response) => {
        setTrackingDetails(response.data)
      }).catch((error) => {
        Swal.fire({
          title: 'Oops',
          text: 'Please Contact GTMS due to outage.'
        })
      })
    }
  }

  return (
    <div className='LandingPage'>
    <div className="LandingPageContainer">
      <NavBar/>
      <Sidebar/>
      <div className="trackingMapPageContent">
        <div className="trackingMapTop">
          <div className="trackingMapTitle">
            <div className="MapTrackingTitle">
              <h1>Ocean Tracking</h1>
            </div>
          </div>
        </div>
        <div className="trackingMapMid">
          <Map coordinates={coordinates}/>
        </div>
        <div className="trackingMapBottom">
          <div className="trackingMapBotLeft">
            <div className="trackingMapBotLeftTop">
              <h3>Filter By:</h3>
            </div>
            <div className="trackingMapBotLeftMid">
              <div className="MapSelectControls">
                <div className="selectFilter">
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel>Filter By</InputLabel>
                    <Select
                      id="selectFilter"
                      label="selectFilter"
                      value={selectFilter}
                      onChange={handleSelectFilter}
                    >
                      <MenuItem value={'VESSEL_CONTAINERS'}>Vessel Tracking</MenuItem>
                      <MenuItem value={'CONTAINERS'}>Container</MenuItem>
                      <MenuItem value={'BOL_NUMBER'}>GTMS BOL</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="selectValue">
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="combo-box-demo"
                    getOptionLabel={(option) => String(option.VALUE)}
                    options={selectValues}
                    sx={{ m: 1, minWidth: 200 }}
                    renderInput={(params) => <TextField {...params} label="Select Value" />}
                    disabled= {!selectFilter}
                    onChange={(event, selectedOption) => handleSelectValue(selectedOption)}
                    value={selectedOption === '' ? null : selectedOption}
                  />
                </div>
                <div className="submitMapTracking">
                  <Button
                    variant="contained"
                    disabled={!selectValues}
                    onClick={submit}>
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="trackingMapBotRight">
            <div className="trackingDetailsTop"></div>
            <div className="trackingDetailsMid">
              <TrackingDetails data = {trackingDetails}/>
            </div>
            <div className="trackingDetailsBottom"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default TrackingMap