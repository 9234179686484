import DateField from '../../../../components/common/datetime/DateField';
import Uploader from '../../../../components/files/uploader/Uploader';
import SelectBox from '../../../../components/common/SelectBox';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import './css/stepEight.css';

const StepEight = ({ formData, onChange, onFileUpload }) => {
  const [uploadBoxes, setUploadBoxes] = useState([{ id: Date.now() }]);
  const [localFormData, setLocalFormData] = useState(formData);
  const [pickupDate, setPickupDate] = useState(null);
  const startDateObject ={ label: 'Pick Up Date'}

  const selectFileType = { label: 'File Type', 
    menuItems: ['Packing List', 'Cert of Authenticity', 'Other']
  }

  const handlePickupDateChange = (value) => {
    setPickupDate(value);
    handleChange({ target: { id: 'pickUpDate', value } });
  };

  const handleSelectFileTypeValue = (id, value) => {
    setUploadBoxes((prevBoxes) =>
      prevBoxes.map((box) =>
        box.id === id ? { ...box, fileType: value } : box
      )
    );
  };

  const handleFileSelect = (id, file) => {
    setUploadBoxes((prevBoxes) =>
      prevBoxes.map((box) =>
        box.id === id ? { ...box, file } : box
      )
    );
  };

  const handleChange = (e) => {
    let {id, value} = e.target;
    setLocalFormData(prevState => ({ ...prevState, [id]: value }));
    onChange({...localFormData, [id]: value})
  }

  const addUploadBox = () => {
    setUploadBoxes([...uploadBoxes, { id: Date.now() }]);
  };

  useEffect(() => {
    onFileUpload(uploadBoxes);
  }, [uploadBoxes])

  return (
    <div className="BiovationDomStepEight">

      <div className="biovationFileSection">
        {uploadBoxes.map((box) => (
          <div key={box.id} className="biovationUploadBox">
            <div className="biovationSelectBox">
              <SelectBox
                selectValue={(value) => handleSelectFileTypeValue(box.id, value)} 
                selectObject={selectFileType} />
            </div>
            <div className="biovationUploadButton">
              <Uploader id={box.id} onFileSelect={handleFileSelect}/>
            </div>
          </div>
        ))}
        <div className="biovationFileButton">
          <Button className='half-width-button' onClick={addUploadBox}>
            Add File Upload
          </Button>
        </div>
      </div>
      <hr></hr>
      <div className="pickupDate" style={{ width: '25%', margin: '0 auto', padding: '20px' }}>
        <DateField setDate={handlePickupDateChange} dateObject={startDateObject}/>
      </div>
      <div className="notes">
        <TextareaAutosize
          id='biovationNotes' 
          placeholder="Enter your notes here..."
          style={{ width: '100%', minHeight: '200px' }}
          value={formData[`biovationNotes`] || ''} 
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

export default StepEight