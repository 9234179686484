import useOriginData from '../../../../hooks/forms/biovations/useOriginData';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import React, { useState } from 'react';
import Cookies from 'js-cookie';

const StepTwo = ({ formData, onChange }) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const { localFormData, handleChange } = useOriginData(Cookies.get('cp-un'), formData, onChange);
  
  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  return (
    <div className="BiovationDomStepTwo">
      <div className="BiovationFormContainer">
        <div className="BiovationFormFieldsStep2">
          <div className="BiovationColumn">
            <div className="BiovationDomField">
              <TextField size="small" id="originName" label="Origin Name" 
                variant="outlined" value={localFormData.originName || ''} 
                onChange={handleChange} required
              />
            </div>
            <div className="BiovationDomField">
              <TextField  size="small" id="originAddress1" label="Origin Address 1" 
              variant="outlined" value={localFormData.originAddress1 || ''} 
              onChange={handleChange} required
              />
            </div>
            <div className="BiovationDomField">
              <TextField size="small" id="originAddress2" label="Origin Address 2" 
              variant="outlined" value={localFormData.originAddress2 || ''} 
              onChange={handleChange}
              />
            </div>
            <div className="BiovationDomField">
              <TextField size="small" id="originContact" label="Origin Contact" 
              variant="outlined" value={localFormData.originContact || ''} 
              onChange={handleChange} required
              />
            </div>
            <div className="BiovationDomField">
              <TextField size="small" id="originPhone"label="Origin Phone" 
              variant="outlined" value={localFormData.originPhone || ''} 
              onChange={handleChange} required
              />
            </div>
          </div>
          <div className="BiovationColumn">
            <div className="BiovationDomField">
              <TextField size="small" id="originEmail" label="Origin Email" 
              variant="outlined" value={localFormData.originEmail || ''} 
              onChange={handleChange} required
              />
            </div>
            <div className="BiovationDomField">
              <TextField  size="small" id="openTime" label="Open Time" 
                variant="outlined" value={formData.openTime  || ''} onChange={handleChange} 
                required
              />
            </div>
            <div className="BiovationDomField">
              <TextField  size="small" id="closeTime" label="Close Time" 
                variant="outlined" value={formData.closeTime  || ''} onChange={handleChange} 
                required
              />
            </div>
            <div className="BiovationDomField">
              <TextField  size="small" id="pickupNumber" label="Pickup Number" 
                variant="outlined" value={formData.pickupNumber  || ''} onChange={handleChange} 
                required
              />
            </div>
            <div className="BiovationFirstServe">
              <label>
                <Checkbox checked={isCheckboxChecked} onChange={handleCheckboxChange}/>
                Is dock first come first serve?
              </label>
              <TextField
                size="small" id="dockFirstServe" label="Include all pick-up information here" 
                variant="outlined" value={formData.dockFirstServe  || ''} onChange={handleChange} 
                disabled={!isCheckboxChecked} 
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StepTwo