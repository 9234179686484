export const useStepHandlers = (formData, setFormData) => {
    const handleChange = (e, step) => {
      const { id, value, type, checked } = e.target;
      const inputValue = type === 'checkbox' ? checked : value;
      const updatedStepData = { ...formData[step], [id]: inputValue };
      setFormData(prevFormData => ({ ...prevFormData, [step]: updatedStepData }));
    };
  
    return { handleChange };
  };
  