import { getCountry } from '../../utils/api/saasLocation';
import React, { useState, useEffect } from 'react';

export const useFetchCountries = () => {
    const [countryList, setCountryList] = useState([]);
  
    useEffect(() => {
      const fetchData = async () => {
        const countries = await getCountry();
        setCountryList(countries);
      };
      fetchData();
    }, []);
  
    return countryList;
  };