import { getPostal } from '../../utils/api/saasLocation';
import React, { useState, useEffect } from 'react';

export const useFetchPostal = (country, state, city) => {
    const [postalList, setPostalList] = useState([]);
  
    useEffect(() => {
      if (country && state && city) {
        const fetchData = async () => {
          const postals = await getPostal(state, city);
          setPostalList(postals);
        };
        fetchData();
      }
    }, [country, state, city]);
  
    return postalList;
  };