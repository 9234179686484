import gtmsLogo from '../../components/images/logo_gtms/gtms_logo.png';
import PurpleTranTitle from './customer/PurpleTranTitle';
import companyLogos from '../../utils/companyLogos.json';
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './customizedTitle.css';

const CustomizedTitle = ({ titleObject }) => {
  const [title, setTitle] = useState(null);
  const [customerLogo, setCustomerLogo] = useState(null);
  const [companyName, setCompanyName] = useState(null);

    const getCompany = () => {
      setCompanyName(Cookies.get('cp-cn'));
    };

    useEffect(() => {
      getCompany();
      setTitle(titleObject.title)
      getLogo();
    }, [titleObject]);

    const getLogo = async() => {
      try {
        const logoFile = companyLogos[companyName];
        const logoModule = await import(`../../assets/logos/${logoFile}`);
        setCustomerLogo(logoModule.default);
      } catch(error) {
        setCustomerLogo(null)
      }
    };

  return (
    <div className='customizedTitle'>
      <div className="centerTitle">
        <h1>
          {title && title.startsWith('Purple Transportation Shipment-Trac') ? (
            <PurpleTranTitle title={title}/>
          ) : (
            <span style={{ color: 'black' }}>{title}</span>
          )}
        </h1>
      </div>
      <div className="customTitleRow">
        <div className='customerLogo'>
          {customerLogo && <img src={customerLogo} alt="Company Logo" style={{height: '50px'}}/>}
        </div>
        <div className="customTitlepowerLogo">
          <div className="customTitlegtmsPower">
            <p>Powered By:</p>
          </div>
          <img src={gtmsLogo} className='gtmsimg' alt="GTMS Logo" />
        </div>
      </div>
    </div>
  );
};

export default CustomizedTitle;
