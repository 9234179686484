import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const useFormId = (formType) => {
    const [formId, setFormId] = useState('');

    useEffect(() => {
        const newUniqueId = uuidv4();
        const truncatedUniqueId = newUniqueId.substring(0, 13);
        const generateFormId = `${formType}-${truncatedUniqueId}`;
        setFormId(generateFormId);
    }, [formType]);
    
    return formId;
}

export default useFormId