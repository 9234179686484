import blueBadge from '../../../assets/vectors/blue-badge.png';
import React from 'react';
import './css/badges.css';

const Badges = (badgeObject) => {
  if (!badgeObject || !badgeObject.chartData) {
    return null;
  }

  return (
    <div className='badgeContainer'>
      <div className="badgesContainer">
        {badgeObject.chartData.map((badge, index) => (
          <div className="badges" key={index}>
            <img src={blueBadge} alt="badge" className={`badgeImage ${!badge.COLORED && 'grayed-out'}`} />
            <div className="badgeText">{badge.DESCRIPTION}</div>
          </div>
        ))}
      </div>
      <div className='badgeNote'>
        <h5>Colored badges indicate available services; gray badges mean unavailable. Contact GTMS for details.</h5>
      </div>
    </div>
  );
};

export default Badges;