import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { config } from '../../../../config/Constants';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import React, { useState, useEffect } from 'react';
import Axios from 'axios';

const StepSix = ({ formData, onChange }) => {
  const [localFormData, setLocalFormData] = useState(formData);
  const [accessorials, setAccessorials] = useState([]);
  
  useEffect(() => {
      getAccessorials()
  }, []);

  const handleCheckboxChange = (value) => {
    const updatedFormData = { ...localFormData };
    updatedFormData[value] = !updatedFormData[value];
    setLocalFormData(updatedFormData);
    onChange(updatedFormData);
  };

  const getAccessorials = async() => {
    const response = await Axios.get(config.url.GET_FORM_ACCESSORIALS, {
      params: {
        companyName: 'Biovation Labs',
        formType: 'Biovation Domestic Form'
      }
    });
    setAccessorials(response.data.accessorials);
  }

  const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)', 
    gap: '20px',
  };

  return (
    <div className="BiovationDomStepFive">
      <div className="BiovationFormContainer">
        <div className="BiovationAccessorialFields">
          <FormControl component="fieldset">
            <FormLabel component="legend">Select Items:</FormLabel>
            <FormGroup style={gridContainerStyle}>
              {accessorials.map((value) => (
                <FormControlLabel
                  key={value}
                  control={
                    <Checkbox
                      checked={localFormData[value] || false}
                      onChange={() => handleCheckboxChange(value)}
                    />
                  }
                  label={value}
                />
              ))}
            </FormGroup>
          </FormControl>
        </div>
      </div>
    </div>
  )
}

export default StepSix