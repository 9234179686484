import NavBar from '../../../components/navbar/NavBar';
import Sidebar from '../../../components/sidebar/Sidebar';
import './unauthorized.css';
import React from 'react';

const unauthorized = () => {

  return (
    <div className="unauthorizedPage">
        <div className="unauthorizedPageContainer">
            <NavBar/>
            <div className="unauthorizedPageContent">
                <div className="unauthorizedPageTop">
                    <div className="unauthorizedTitle">
                        <h1>Unauthorized Access</h1>
                    </div>
                </div>
                <div className="unauthorizedPageMid">
                    <div className="unauthorizedBody">
                        <p>We apologize for any inconvenience caused, but it appears
                            that you are not authorized to view this page.<br/>
                            If you believe this to be an error or require access,
                            please do not hesitate to contact us for assistance.<br/>
                            Our team will be glad to help you resolve any issues or
                            provide the necessary authorization.<br/>
                            <strong>If you have just created an account please be advised that
                            our IT team is conducting a thorough verification of the account to
                            ensure your data is secure.</strong> <br/>
                            Thank you for your understanding.<br/>
                            - GTMS</p>
                    </div>
                </div>
                <div className="unauthorizedPageBottom"></div>
            </div>
        </div>
    </div>
  )
}

export default unauthorized