import ReportCard from '../../../components/reportCard/ReportCard';
import usePageView from '../../../hooks/analytics/usePageView.js';
import Sidebar from '../../../components/sidebar/Sidebar';
import NavBar from '../../../components/navbar/NavBar';
import verifyToken from "../../../utils/tokenUtils.js";
import { config } from "../../../config/Constants.js";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './trackinglanding.css';
import Axios from 'axios';

const TrackingLanding = () => {
  usePageView();
  const navigate = useNavigate();
  const [trackingMap, setTrackingMap] = useState([{}]);

  const getCustomer = async() => { return Cookies.get('cp-cn'); }
  const getAuthToken = () => { return Cookies.get('gtms-cp-auth'); };

  useEffect(() => {
    const token = getAuthToken();
    token ? verifyToken(token) : navigate('/');
    getReports();
  }, []);

  const getReports = async () => {
    const customer = await getCustomer();
    const response = await Axios.get(config.url.GET_TRACKING_REPORTS, {
      params: { customer: customer, }
    });
    setTrackingMap(response.data.reports);
  }

  const handleClick = async (id) => {
    navigate(`/trackings/${id}`); 
  }

  return (
    <div className='LandingPage'>
      <div className="LandingPageContainer">
        <NavBar/>
        <Sidebar/>
        <div className="trackingLandingpageContent">
          {trackingMap.map(reports =>
            <ReportCard
              key={reports.ID}
              title={reports.REPORT_NAME}
              description={reports.REPORT_DESCRIPTION}
              onClick={() => handleClick(reports.ID)}
              />
            )}
        </div>
      </div>
    </div>
  )
}

export default TrackingLanding