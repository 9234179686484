import TrackingTitle from '../../../components/customizedTitle/trackingTitle/TrackingTitle';
import CircularProgress from '@mui/material/CircularProgress';
import SelectBox from '../../../components/common/SelectBox';
import SearchBar from '../../../components/common/SearchBar';
import Table from '../../../components/tables/dynamicTable';
import fieldMap from '../../../utils/fieldMap.json';
import { config } from '../../../config/Constants';
import React, { useEffect, useState } from 'react';
import { useLocation  } from 'react-router-dom';
import FileDownload from 'js-file-download';
import { Button } from '@mui/material';
import './trackingcenter.css';
import Papa from 'papaparse';
import Axios from 'axios';

const TrackingCenter = () => {
  const location = useLocation ();
  const currentPath = location.pathname;
  const [searchValue, setSearchValue] = useState('');
  const [selectValue, setSelectValue] = useState('');
  const [trackingData, setTrackingData] = useState([]);
  const [isData, setIsData] = useState(false);
  const [fieldName, setFieldName] = useState([]);
  const [source, setSource] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const searchConfig = {size: 'normal', keyPress: true}
  const dataObject = {data: trackingData,}
  const selectObject = { label: 'Tracking Type', menuItems: fieldName }

  let title;
  switch(currentPath) {
    case ('/trackingcenter'):
      title = 'Tracking Center';
      break;
    case ('/purpleordertrac'):
      title = 'Purple Transportation Order-Trac';
      break;
    case ('/vivinttracking'):
      title = 'Vivint Tracking Center'
      break;
      case ('/biovationtracking'):
        title = 'Biovation Labs Tracking Center'
        break;
    default:
      title = 'Tracking Center';
  }

  useEffect(() => {
    if(currentPath == '/purpleordertrac') {
      setFieldName(fieldMap['Purple']['Tracking Center']['Select Fields']);
      setSource(fieldMap['Purple']['Tracking Center']['Source']);
    } else if (currentPath == '/vivinttracking') {
      setFieldName(fieldMap['Vivint']['Tracking Center']['Select Fields']);
      setSource(fieldMap['Vivint']['Tracking Center']['Source']);
    } else if (currentPath == '/biovationtracking') {
      setFieldName(fieldMap['Biovation Labs']['Tracking Center']['Select Fields']);
      setSource(fieldMap['Biovation Labs']['Tracking Center']['Source']);
    }
    else {
      setFieldName(fieldMap['GTMS']['Tracking Center']['Select Fields']);
      setSource(fieldMap['GTMS']['Tracking Center']['Source']);
    }
  }, []);

  const inputValue = (value) => {
    setSearchValue(value);
  };

  const handleSelectValue = (value) => {
    setSelectValue(value);
  };

  const convertToCSV = () => {
    const csv = Papa.unparse(trackingData);
    return csv;
  };

  const onSearch = (value) => {
    setSearchValue(value);
    handleSubmitClick()
  }

  const handleSubmitClick = async() => {
    if (searchValue && selectValue) {
      setIsLoading(true);
      const data = await getTrackingData();
      setIsData(true);
      setTrackingData(data);
      setIsLoading(false);
    }
  };

  const getTrackingData = async() => {
    const response = await Axios.get(config.url.GET_TRACKING_DATA, {
      params: {
        selectValue: selectValue,
        searchValue: searchValue,
        source: source
      }
    })
    return response.data.trackingDetails;
  }

  const handleDownload = () => {
    const csvData = convertToCSV()
    FileDownload(csvData, `${searchValue}.csv`);
  }

  return (
  <div className="trackingCenterPage">
    <div className="trackingCenterContainer">
      <div className="trackingCenterTop">
        <div className="trackingCenterTitle">
          <h1>
            {title.startsWith('Purple') ? (
              <span style={{ color: 'purple' }}>Purple Transportation</span>
            ) : 
            title.startsWith('Vivint') ? (
              <span> Vivint Tracking </span>
            ) : (
              title
            )}{' '}
            {title.startsWith('Purple')
              ? title.substring(title.indexOf(' ', title.indexOf(' ') + 1) + 1)
              : ''}
          </h1>
        </div>
        <TrackingTitle currentPath={currentPath} />
      </div>
      <div className="trackingCenterMid">
        <div className="trackingCenterSearch">
          <div className="trackingCenterRow1">
            <div className="trackingCenterFilter">
              <SelectBox selectValue={handleSelectValue} selectObject={selectObject}/>
            </div>
            <div className="trackingCenterSearchBar">
              <SearchBar inputValue={inputValue} onSearch={onSearch} config={searchConfig}/>
            </div>
          </div>
          <div className="trackingCenterRow2">
            <div className="trackingCenterSubmit">
              <Button 
                variant='contained'
                sx={{padding: '14px 250px', backgroundColor: 'orange'}}
                onClick={handleSubmitClick}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Get Tracking'}
                </Button>
            </div>
          </div>
        </div>
        <div className="horizontalLine"><hr/></div>
        {isData ? (
          <div className="trackingInfo">
            <div className="trackingCenterThirdScreenWidth">
              <div className="TrackingCentereDetailTitle">
                <h3>Tracking Details:</h3>
              </div>
              <div className="trackingCenterDetails">
                <Table dataObject={dataObject}/>
              </div>
              <div className="downloadTracking">
                <Button variant="contained"
                onClick={handleDownload}>Download Tracking</Button>
              </div>
            </div>
          </div>
        ) : (
          <>
          <div></div>
        </>
        )}
      </div>
      <div className="trackingCenterBottom"></div>
    </div>
  </div>
  )
}

export default TrackingCenter