import React, {useCallback, useState} from 'react';
import { config } from '../../../config/Constants';
import { useDropzone } from 'react-dropzone';
import Axios from 'axios';
import './dropbox.css';
import UploadFileIcon from '@mui/icons-material/UploadFile';

function Dropbox({setFileInfo}) {

  const onDrop = useCallback(async (acceptedFiles) => {
    const filesArray = [];
    const fileInfoArray = [];

    for (const file of acceptedFiles) {
      const fd = new FormData();
      fd.append('files', file);
      const fileInfo = await tempFileUpload(fd);
      filesArray.push(fileInfo[0].originalName);
      fileInfoArray.push(fileInfo);
    }

    setFiles(filesArray);
    setFileInfo(fileInfoArray);
  }, []);

  const tempFileUpload = async(fd) => {
    const response = await Axios.post(config.url.TEMP_FILE_UPLOAD, fd, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data.fileInfo;
  }

  const {getRootProps, getInputProps} = useDropzone({onDrop})
  const [files, setFiles] = useState([]);

  return (
    <div {...getRootProps({className: 'dropzone'})}>
      <input {...getInputProps()} />
      <h4>Drag & drop file here, or click to select file</h4>
      <h4 className='fileHeader'>
        &nbsp; <u>File Name Uploaded:</u>
      </h4>
      <div>
        <ul className='file'>
          {files.map((file, index) => (
            <div key ={index} className='fileRow' >
              <UploadFileIcon style={{fontSize: 30}}/>
              {file}
            </div>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Dropbox