import usePageView from '../../../hooks/analytics/usePageView.js';
import Sidebar from '../../../components/sidebar/Sidebar';
import NavBar from '../../../components/navbar/NavBar';
import verifyToken from "../../../utils/tokenUtils.js";
import React, { useEffect, useState } from 'react';
import { config } from '../../../config/Constants';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';
import Axios from 'axios';
import './admin.css';

const Admin = () => {
  usePageView();
  const navigate = useNavigate();
  const getAuthToken = () => { return Cookies.get('gtms-cp-auth'); };
  const getUsername = () => { return Cookies.get('cp-un') };

  useEffect(() => {
    const token = getAuthToken();
    token ? verifyToken(token) : navigate('/');
    verifyAdmin();
  }, []);

  const verifyAdmin = async() => {
    const username = getUsername();
    const response = await Axios.get(config.url.VERIFY_ADMIN, {
      params: { username: username, }
    });
    const isAdmin = response.data.isVerified.IS_ADMIN;
    if (isAdmin !== true) {
      navigate('/unauthorized');
    }
  }

  const adminNavigation = (route) => {
    switch (route) {
      case '1':
        navigate('/companyinformation');
        break;
      case '2':
        navigate('/adminaccountinformation');
        break;
      case '3':
        navigate('/changeadmincompany');
        break;
      case '4':
        navigate('/viewusers');
        break;
      default:
        break;
    }
  }

  return (
    <div className="LandingPage">
        <div className="LandingPageContainer">
            <NavBar/>
            <Sidebar/>
            <div className="adminLandingPageContent">
              <div className="adminLandingTop">
                <div className="adminLandingTitle">
                  <h1>Admin Landing Page</h1>
                </div>
              </div>
              <div className="adminLandingMid">
                <div className="adminLandingLinks">
                  <div className="adminLandingButton1">
                    <Button 
                      className='adminSquareButton' 
                      variant="contained" 
                      onClick={() => adminNavigation('1')}>
                      Update Company Information
                    </Button>
                  </div>
                  <div className="adminLandingButton2">
                    <Button
                      className='adminSquareButton' 
                      variant="contained"
                      onClick={() => adminNavigation('2')}>
                      Update Account Information
                    </Button>
                  </div>
                  <div className="adminLandingButton3">
                    <Button className='adminSquareButton' variant="contained">
                      Change Admin Company
                    </Button>
                  </div>
                  <div className="adminLandingButton4">
                    <Button className='adminSquareButton' variant="contained">
                      View Users
                    </Button>
                  </div>
                </div>
              </div>
              <div className="adminLandingBottom"></div>
            </div>
        </div>
    </div>
  )
}

export default Admin