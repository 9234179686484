import React from 'react';
import 'chart.js/auto';
import { Pie } from 'react-chartjs-2';

const PieChart = ({chartData}) => {

    if (!chartData || chartData.length === 0) {
        return <div>No data provided for the pie chart.</div>;
      }

    const labels = chartData.map(item => item.LABEL);
    const values = chartData.map(item => item.VALUE);
    
    const data = {
        maintainAspectRatio: false,
        labels: labels,
        datasets: [{
            data: values,
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
            hoverBackgroundColor: [ '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
            },]
    };

    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: { position: 'bottom', },
            tooltip: { callbacks: {
                label: (context) => {
                    const value = context.formattedValue;
                    return `${value}`;
                },
            }, },
        },
    };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Pie data={data} options={options} />
        </div>
    );
};

export default PieChart;
