import DateField from '../../common/datetime/DateField';
import { config } from '../../../config/Constants';
import React, {useEffect, useState} from 'react';
import SelectBox from '../../common/SelectBox';
import { Button, Menu} from '@mui/material';
import Cookies from 'js-cookie';
import Axios from 'axios';
import './css/auditChartFilter.css';

const AuditChartFilter = ({handleAuditFilter}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [serviceDescriptions, setServiceDescriptions] = useState(['']);
  const [serviceDescriptionSelect, setServiceDescriptionSelect] = useState(null);

  const startDateObject ={ label: 'Start Date'}
  const endDateObject ={ label: 'End Date'}

  const selectObject = {
    label: 'Service (Optional)',
    menuItems: serviceDescriptions,
  }

  const getCompany = () => {
    return Cookies.get('cp-cn');
  }

  useEffect(() => {
    getCompanyServiceDescriptions();
  }, [])

  const getCompanyServiceDescriptions = async() => {
    const companyName = getCompany();
    const response = await Axios.get(config.url.GET_AUDIT_SERVICE_DESC, {
      params: {
        companyName: companyName
      }
    })
    setServiceDescriptions(response.data.serviceDesc);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStartDateChange = (value) => {
    setStartDate(value)
  }

  const handleEndDateChange = (value) => {
    setEndDate(value)
  }

  const handleSelectValue = (value) => {
    setServiceDescriptionSelect(value)
  }

  const handleFilterClick = () => {
    const filterObject = {
      startDate: startDate,
      endDate: endDate,
      serviceDescription: serviceDescriptionSelect,
    }
    handleAuditFilter(filterObject);
  }

  return (
  <div className='AuditChartFilter'>
    <Button
      aria-controls="filter-menu"
      aria-haspopup="true"
      variant="outlined"
      onClick={handleClick}
    >
      Filter Chart
    </Button>
    <Menu
      id="filter-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <div className="auditFilterStartDate">
        <DateField setDate={handleStartDateChange} dateObject={startDateObject}/>
      </div>
      <div className="auditFilterEndDate">
        <DateField setDate={handleEndDateChange} dateObject={endDateObject}/>
      </div>
      <div className="auditFilterSelectService">
        <SelectBox selectValue={handleSelectValue} selectObject={selectObject}/>
      </div>
      <div className="auditFilterSubmit">
        <Button variant="contained" onClick={handleFilterClick}>
          Submit
        </Button>
      </div>
    </Menu>
  </div>
  )
}

export default AuditChartFilter