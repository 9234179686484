import BiovationsDomestic from "./pages/laneview/forms/biovationsdomestic/BiovationsDomestic";
import AdminAccountInformation from "./pages/admin/accountInformation/AccountInformation";
import CompanyInformation from "./pages/admin/companyInformation/CompanyInformation";
import TrackingRenderer from "./components/dynamicRenderer/TrackingRenderer";
import TrackingCenter from "./pages/tracking/trackingcenter/TrackingCenter";
import K2jDomestic from "./pages/laneview/forms/k2jdomestic/K2jDomestic";
import ReportRenderer from "./components/dynamicRenderer/ReportRenderer";
import ShipmentTrack from "./pages/laneview/shipmenttrack/ShipmentTrack";
import ForgotPassword from "./pages/auth/forgotpassword/ForgotPassword";
import TrackingLanding from "./pages/landing/tracking/TrackingLanding";
import CsvBooking from "./pages/laneview/forms/csvbooking/CsvBooking";
import FormRenderer from "./components/dynamicRenderer/FormRenderer";
import ResetPassword from "./pages/auth/resetpassword/ResetPassword";
import LoadQuote from "./pages/laneview/forms/loadquote/LoadQuote";
import LoadQuoteAdmin from "./pages/laneview/loadquote/Loadquote";
import ReportLanding from "./pages/landing/reports/ReportLanding";
import Unauthorized from "./pages/auth/unauthorized/unauthorized";
import {Route,  BrowserRouter, Routes } from "react-router-dom";
import FormsLanding from "./pages/landing/forms/FormsLanding";
import HomePage from "./pages/landing/homepage/HomePage";
import Register from "./pages/auth/register/Register";
import AuditCenter from "./pages/audit/AuditCenter";
import Admin from "./pages/landing/admin/Admin";
import Login from "./pages/auth/login/Login";
import Remit from "./pages/remit/Remit";
import ReactGA from 'react-ga4';
import React, { useEffect } from 'react';

function App() {
  useEffect(() => {ReactGA.initialize('G-XQNZNSDM3Z'); }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes path="/">
          <Route index element={<Login/>}/>
          <Route path="register">
            <Route index element={<Register/>}/>
          </Route>
          <Route path="trackingcenter">
            <Route index element={<TrackingCenter/>}/>
          </Route>
          <Route path="purpleordertrac">
            <Route path="/purpleordertrac" element={<TrackingCenter/>}/>
          </Route>
          <Route path="vivinttracking">
            <Route path="/vivinttracking" element={<TrackingCenter/>}/>
          </Route>
          <Route path="biovationtracking">
            <Route path="/biovationtracking" element={<TrackingCenter/>}/>
          </Route>
          <Route path="forgotpassword">
            <Route index element={<ForgotPassword/>}/>
          </Route>
          <Route path="resetpassword">
            <Route index element={<ResetPassword/>}/>
          </Route>
          <Route path="homepage">
            <Route index element={<HomePage/>}/>
          </Route>
          <Route path="admin">
            <Route index element={<Admin/>}/>
          </Route>
          <Route path="adminaccountinformation">
            <Route index element={<AdminAccountInformation/>}/>
          </Route>
          <Route path="companyinformation">
            <Route index element={<CompanyInformation/>}/>
          </Route>
          <Route path="auditcenter">
            <Route index element={<AuditCenter/>}/>
          </Route>
          <Route path="shipmenttrack">
            <Route index element={<ShipmentTrack/>}/>
          </Route>
          <Route path="/forms">
            <Route path=":id" element={<FormRenderer/>} />
          </Route>
          <Route path="formslanding">
            <Route index element={<FormsLanding/>}/>
          </Route>
          <Route path="loadquoteadmin">
            <Route index element={<LoadQuoteAdmin/>}/>
          </Route>
          <Route path="csvbooking">
            <Route index element={<CsvBooking/>}/>
          </Route>
          <Route path="biovationsdomestic">
            <Route index element={<BiovationsDomestic/>}/>
          </Route>
          <Route path="k2jdomestic">
            <Route index element={<K2jDomestic/>}/>
          </Route>
          <Route path="loadquote">
            <Route index element={<LoadQuote/>}/>
          </Route>
          <Route path="reportslanding">
            <Route index element={<ReportLanding/>}/>
          </Route>
          <Route path="reports">
            <Route path=":url" element={<ReportRenderer/>}/>
          </Route>
          <Route path="remits">
            <Route index element={<Remit/>}/>
          </Route>
          <Route path="trackinglanding">
            <Route index element={<TrackingLanding/>}/>
          </Route>
          <Route path="unauthorized">
            <Route index element={<Unauthorized/>}/>
          </Route>
          <Route path="trackings">
            <Route path=":id" element={<TrackingRenderer/>} />
          </Route>         
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
