import useFetchOriginGeo from '../../../../hooks/forms/biovations/useFetchOriginGeo.js';
import { useFetchCountries } from '../../../../hooks/geo/useFetchCountries.js';
import { useFetchCities } from '../../../../hooks/geo/useFetchCities.js';
import { useFetchPostal } from '../../../../hooks/geo/useFetchPostal.js';
import { useFetchStates } from '../../../../hooks/geo/useFetchStates.js';
import SelectBox from '../../../../components/common/SelectBox';
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const StepThree = ({ formData, onChange }) => {
  const [localFormData, setLocalFormData] = useState(formData);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [postal, setPostal] = useState(null);

  const countryList = useFetchCountries();
  const stateList = useFetchStates(country);
  const cityList = useFetchCities(country, state);
  const postalList = useFetchPostal(country, state, city);

  const selectCountry = { label: 'Country', menuItems: countryList || []}
  const selectState = { label: 'State', menuItems: stateList}
  const selectCity = { label: 'City', menuItems: cityList}
  const selectPostal = { label: 'Postal', menuItems: postalList}

  const handleSelectValue = (field, setter, value) => {
    setter(value); // Sets state for country, state, city, or postal
    const updatedFormData = { ...localFormData, [field]: value };
    setLocalFormData(updatedFormData);
    onChange(updatedFormData);
  };

  const geoData = useFetchOriginGeo(Cookies.get('cp-un'));

  useEffect(() => {   //TODO: Make it a hook?
    if (geoData && geoData.length > 0) {
      if (geoData[0].COUNTRY) setCountry(geoData[0].COUNTRY);
      if (geoData[0].STATE) setState(geoData[0].STATE);
      if (geoData[0].CITY) setCity(geoData[0].CITY);
      if (geoData[0].ZIP) setPostal(geoData[0].ZIP);

      const updatedFormData = {
        ...localFormData,
        originCountry: geoData[0].COUNTRY,
        originState: geoData[0].STATE,
        originCity: geoData[0].CITY,
        originPostal: geoData[0].ZIP,
      };
      setLocalFormData(updatedFormData);
      onChange(updatedFormData);
    }
  }, [geoData]);


  return (
    <div className="BiovationDomStepThree">
      <div className="BiovationFormContainer">
        <div className="BiovationFormFields">
          <div id='originCountry' className="BiovationDomField">
            <SelectBox 
              selectValue={(value) => handleSelectValue('originCountry', setCountry, value)} 
              selectObject={selectCountry} 
              initialValue={country}
            />
          </div>
          <div className="BiovationDomField">
            <SelectBox 
              selectValue={(value) => handleSelectValue('originState', setState, value)} 
              selectObject={selectState}
              initialValue={state}
            />
          </div>
          <div className="BiovationDomField">
            <SelectBox 
              selectValue={(value) => handleSelectValue('originCity', setCity, value)} 
              selectObject={selectCity}
              initialValue={city}
            />
          </div>
          <div className="BiovationDomField">
            <SelectBox 
              selectValue={(value) => handleSelectValue('originPostal', setPostal, value)} 
              selectObject={selectPostal}
              initialValue={postal}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default StepThree