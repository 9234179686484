import gtmsLogo from '../../../components/images/logo_gtms/gtms_logo.png';
import LogoGtms from "../../../components/images/logo_gtms/LogoGtms";
import purpleLogo from '../../../assets/logos/purple-logo.png';
import vivintLogo from '../../../assets/logos/vivint-logo.png';
import biovationLogo from '../../../assets/logos/Biovation-labs-logo.png';

const BiovationLabsTrackingLogo = () => (
  <div className="purpleRow">
    <div className='purpleLogo'>
      <img src={biovationLogo}/>
    </div>
    <div className="gtmsLogo2">
      <div className="trackingCenterGTMSPower">
        <p>Powered By:</p>
      </div>
      <img src={gtmsLogo} className='gtmsimg'/>
    </div>
  </div>
);

const PurpleOrderTracLogo = () => (
    <div className="purpleRow">
      <div className='purpleLogo'>
        <img src={purpleLogo}/>
      </div>
      <div className="gtmsLogo2">
        <div className="trackingCenterGTMSPower">
          <p>Powered By:</p>
        </div>
        <img src={gtmsLogo} className='gtmsimg'/>
      </div>
    </div>
  );
  
  const VivintTrackingLogo = () => (
    <div className="purpleRow">
      <div className='purpleLogo'>
        <img src={vivintLogo}/>
      </div>
      <div className="gtmsLogo2">
        <div className="trackingCenterGTMSPower">
          <p>Powered By:</p>
        </div>
        <img src={gtmsLogo} className='gtmsimg'/>
      </div>
    </div>
  );
  
  const TrackingCenterLogo = () => (
    <div className="trackingCenterLogos">
      <div className="trackingCenterLogoPhrase">
        <p>Powered By:</p>
      </div>
      <div className="gtmsLogo">
        <LogoGtms/>
      </div>
    </div>
  );
  
  
  const ConditionalLogo = ({ currentPath }) => {
    switch(currentPath) {
      case '/purpleordertrac':
        return <PurpleOrderTracLogo />;
      case '/vivinttracking':
        return <VivintTrackingLogo />;
      case '/trackingcenter':
        return <TrackingCenterLogo />;
      case '/biovationtracking':
          return <BiovationLabsTrackingLogo />;
      default:
        return null; 
    }
  };
  

export default ConditionalLogo
