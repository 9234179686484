// sweetAlertUtil.js
import Swal from 'sweetalert2';
import ReactDOMServer from 'react-dom/server';

const showDataPopup = (title, data) => {
    const htmlContent = ReactDOMServer.renderToString(
        <div style={{ margin: '0 auto', width: '50%' }}>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr>
              {Object.keys(data[0]).map((header, index) => (
                <th key={index} style={{ border: '1px solid #ddd', padding: '8px' }}>
                    {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, rowIndex) => (
              <tr key={rowIndex}>
                {Object.values(item).map((value, colIndex) => (
                  <td
                  key={colIndex}
                  style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}
                >{JSON.stringify(value)}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );

    Swal.fire({
      title: title,
      html: htmlContent,
      confirmButtonText: 'Close',
      confirmButtonColor: '#1769aa',
    });
  };

export default showDataPopup;