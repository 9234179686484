import { useState, useEffect } from 'react';

const useTransformChartData = (chartData) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchChart = async () => {
      if (chartData && chartData.length === 1) {
        const transformed = Object.entries(chartData[0]).map(([key, value]) => ({
          LABEL: key,
          VALUE: value
        }));
        setData(transformed);
      } else {
        setData([]);
      }
    };

    fetchChart();
  }, [chartData]);

  return data;
};

export default useTransformChartData;
