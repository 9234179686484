import { useState, useEffect } from 'react';
import { getSelectData } from '../../../utils/api/data';

const useFetchOriginGeo = (name) => {
    const [geoLocation, setGeoLocation] = useState(null)
    useEffect(() => {
        const getLocationData = async() => {
            const data = await getSelectData({
                selectValues:  'ZIP, STATE, CITY, COUNTRY',
                tableName: 'CustomerPortal.forms.BIOVATIONS_VENDORS_ORIGIN_LOCATION',
                whereField: 'USERNAME',
                whereValue: name,
              });
              setGeoLocation(data)
        }
    getLocationData();
    }, [])

    return geoLocation
}

export default useFetchOriginGeo;