import { getSelectData } from '../../../utils/api/data';
import React, { useState, useEffect } from 'react';

const useFields = (queryConfig) => {
  const [fields, setFields] = useState(null)
  useEffect(() => {
      const getfields = async() => {
          const data = await getSelectData({
              selectValues: queryConfig.fields,
              tableName: queryConfig.table,
              whereField: queryConfig.where,
              whereValue: queryConfig.value
            });
            setFields(data)
      }
      getfields();
  }, [])

  return fields
}

export default useFields