import usePageView from "../../../hooks/analytics/usePageView.js";
import { config } from "../../../config/Constants.js";
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import React, { useState } from "react";
import ReactGA from 'react-ga4';
import Swal from 'sweetalert2';
import './forgotpassword.css';
import Axios from 'axios';

const ForgotPassword = () => {
  usePageView();
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [processing, setProcessing] = useState(false);

  const submit = async() => {
    ReactGA.event({ category: 'Button', action: 'Click', label: 'Reset Password Button', value: 1 });
    if (email) {
      setProcessing(true);
      try {
        const response = await Axios.post(config.url.FORGOT_PASSWORD, { 
            email: email,
          }
        );

        if (response.data.emailSent === true) {
          handleEmailSuccess();
        } else {
          handleEmailError();
        }
      } catch (error) {
        handleEmailError();
      } finally {
        setProcessing(false);
      }
    } else {
      handleEmailError();
    }
  }

  const handleEmailSuccess = () => {
    Swal.fire({
      icon: 'success',
      title: 'Email Reset Link Sent to Email.',
    });
  };

  const handleEmailError = () => {
    Swal.fire({
      icon: 'error',
      title: 'Failed to send the email.',
    });
  };

  const toLoginPage = () => {
    navigate('/')
  }

  return (
    <div className="forgotPasswordPage">
      <div className="forgotPasswordContainer">
        <div className="forgotPasswordTop">
          <div className="forgotPasswordTitle">
            <h1>Forgot Password?</h1>
          </div>
          <div className="restPasswordDescription">
            <p>A password reset link will be sent to your email address.
              If you encounter any further issues, please feel free to contact GTMS.</p>
          </div>
        </div>
        <div className="forgotPasswordMid">
          <TextField
            style={{width: '500px'}}
            id="forgotPasswordEmail"
            label="Email"
            variant="outlined"
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className="forgotPasswordBottom">
          <div className="forgotPasswordButtons">
            <div className="forgotPasswordSubmitButton">
              <Button 
                variant="contained"
                onClick={submit}
                disabled={processing}>
                {processing ? 'Processing...' : 'Submit'}
              </Button>
            </div>
            <div className="forgotPasswordback">
              <Button 
                variant="outlined"
                onClick={toLoginPage}>
                Back
              </Button>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword