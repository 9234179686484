import companyPreferences from '../../../utils/companyPreferences.json';
import Cookies from 'js-cookie';

export const getCustomerRowColor = (index) => {
  const company = Cookies.get('cp-cn');
  const color = companyPreferences?.[company]?.colors?.secondaryColor ?? null;

  if (color) {
    return index % 2 === 0 ? color: '#ffffff';
    } else {
      return 'transparent';
  }
}

export const getDefaultRowColor = (index) => {
    return index % 2 === 0 ? '#F5F5F5': '#ffffff';
}