import SelectBox from '../../../../components/common/SelectBox';
import TextField from '@mui/material/TextField';
import { Button } from '@material-ui/core';
import React, {useState} from 'react';

const StepSeven = ({ formData, onChange }) => {
  const [localFormData, setLocalFormData] = useState(formData);
  const [formCount, setFormCount] = useState(1);

  const handleChange = (e) => {
    let {id, value} = e.target;
    setLocalFormData(prevState => ({ ...prevState, [formCount]: { ...prevState[formCount], [id]: value } }));
    onChange({ ...localFormData, [formCount]: { ...localFormData[formCount], [id]: value } });
  }

  const handleGenerateForm = () => {
    setFormCount((prevCount) => prevCount + 1);
  };

  const selectClass = { label: 'Class', 
    menuItems: ['50', '55', '60', '65', '70', '75', '77.5', '85', '92.5', '100', '110', 
                '125', '150', '175', '200', '250', '300', '400', '500']
  }

  const selectPackage = { label: 'Package', menuItems: ['Pallet', 'Box']}

  const handleClassSelectValue = (value) => { 
    handleChange({ target: { id: 'class', value: value } });
  }

  const handlePackageSelectValue = (value) => { 
    handleChange({ target: { id: 'package', value: value } });
  }

  const handleKeyDown = (e) => {
    if (!(e.key === '.' || (e.key >= '0' && e.key <= '9') || e.key === 'Backspace' 
    || e.key === 'Delete' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Home' 
    || e.key === 'End' || e.key === 'Tab')) {
      e.preventDefault();
    }
  };

  const renderFormFields = () => {
    const fields = [];
    for (let i = 0; i < formCount; i++) {
      fields.push(
        <div key={i}>
          <p>Enter Detail Fields:</p>
          <div className="BiovationCol">
            <div className="BiovationDomField">
              <TextField  size="small" id='palletNumber' label="Pallet Number" variant="outlined"
                value={(formData[i+1] && formData[i+1]['palletNumber']) || ''}  onChange={handleChange} required 
                onKeyDown={handleKeyDown} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*'  }}
              />
            </div>
            <div className="BiovationDomField">
              <TextField  size="small" id={'pieces'} label="Pieces" variant="outlined"
                value={(formData[i+1] && formData[i+1]['pieces']) || ''} 
                onChange={handleChange} required
                onKeyDown={handleKeyDown} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*'  }}
              />
            </div>
            <div className="BiovationDomField">
              <TextField size="small" id={'sku'} label="BVL" variant="outlined"
                value={(formData[i+1] && formData[i+1]['sku']) || ''} 
                onChange={handleChange} required
              />
            </div>
            <div className="BiovationDomField">
              <TextField size="small" id={'description'} label="Description" variant="outlined"
                value={(formData[i+1] && formData[i+1]['description']) || ''} 
                onChange={handleChange} required
              />
            </div>
            <div className="BiovationDomField">
              <TextField size="small" id={'poNumber'} label="PO Number" variant="outlined"
                value={(formData[i+1] && formData[i+1]['poNumber']) || ''} 
                onChange={handleChange} required
              />
            </div>
          </div>
          <div className="BiovationCol">
            <div className="BiovationDomField">
              <TextField size="small" id={'length'} label="Length" variant="outlined"
                value={(formData[i+1] && formData[i+1]['length']) || ''} 
                onChange={handleChange} required
                onKeyDown={handleKeyDown} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*'  }}
              />
            </div>
            <div className="BiovationDomField">
              <TextField size="small" id={'width'} label="Width" variant="outlined"
                value={(formData[i+1] && formData[i+1]['width']) || ''} 
                onChange={handleChange} required
                onKeyDown={handleKeyDown} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*'  }}
              />
            </div>
            <div className="BiovationDomField">
              <TextField size="small" id={'height'} label="Height" variant="outlined"
                value={(formData[i+1] && formData[i+1]['height']) || ''} 
                onChange={handleChange} required
                onKeyDown={handleKeyDown} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*'  }}
              />
            </div>
            <div className="BiovationDomField">
              <TextField size="small" id={'weight'} label="Weight" variant="outlined"
                value={(formData[i+1] && formData[i+1]['weight']) || ''} 
                onChange={handleChange} required
                onKeyDown={handleKeyDown} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*'  }}
              />
            </div>
            <div className="BiovationDomField">
              <TextField size="small" id={'nmfc'} label="NMFC" variant="outlined"
                value={(formData[i+1] && formData[i+1]['nmfc']) || ''} 
                onKeyDown={handleKeyDown} onChange={handleChange} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*'  }}
              />
            </div>
          </div>
          <div className="BiovationCol">
            <div className="BiovationDomField">
              <SelectBox selectValue={handleClassSelectValue} selectObject={selectClass}/>
            </div>
            <div className="BiovationDomField">
              <SelectBox selectValue={handlePackageSelectValue} selectObject={selectPackage}/>
            </div>
          </div>
        </div>
      );
    }
    return fields;
  };

  return (
    <div>
      {renderFormFields()}
      <Button onClick={handleGenerateForm}>Add Field</Button>
    </div>
  );
};

export default StepSeven;