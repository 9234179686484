import { TextField } from '@material-ui/core';
import React from 'react';

const OriginInformation = ({formData, handleChange}) => {
  return (
    <div className="DomesticOriginInformation">
      <div className="OriginInformationFormContainer">
        <div className="OriginInformationFormFieldsStep2">
          <div className="DomesticInformationColumn">
            <div className="OriginInformationField">
              <TextField 
                size="small" 
                id="originName" 
                label="Origin Name" 
                variant="outlined"
                value={formData?.originName || ''}
                onChange={(e) => handleChange(e, 'originInformation')}
                required
              />
            </div>
            <div className="OriginInformationField">
              <TextField  
                size="small" 
                id="originAddress1" 
                label="Origin Address 1" 
                variant="outlined"
                value={formData?.originAddress1 || ''}
                onChange={(e) => handleChange(e, 'originInformation')}
                required
              />
            </div>
            <div className="OriginInformationField">
              <TextField 
                size="small" 
                id="originAddress2" 
                label="Origin Address 2"
                variant="outlined"
                value={formData?.originAddress2 || ''}
                onChange={(e) => handleChange(e, 'originInformation')}
              />
            </div>
            <div className="OriginInformationField">
              <TextField 
                size="small" 
                id="originContact" 
                label="Origin Contact" 
                variant="outlined"
                value={formData?.originContact || ''}
                onChange={(e) => handleChange(e, 'originInformation')}
                required
              />
            </div>
            <div className="OriginInformationField">
              <TextField 
                size="small" 
                id="originPhone"
                label="Origin Phone" 
                variant="outlined"
                value={formData?.originPhone || ''}
                onChange={(e) => handleChange(e, 'originInformation')}
                required
              />
            </div>
          </div>
          <div className="DomesticInformationColumn">
            <div className="OriginInformationField">
              <TextField 
                size="small" 
                id="originEmail" 
                label="Origin Email" 
                variant="outlined"
                value={formData?.originEmail || ''}
                onChange={(e) => handleChange(e, 'originInformation')}
                required
              />
            </div>
            <div className="OriginInformationField">
              <TextField 
                size="small" 
                id="originOpenTime" 
                label="Open Time" 
                variant="outlined"
                value={formData?.originOpenTime || ''}
                onChange={(e) => handleChange(e, 'originInformation')}
                required
              />
            </div>
            <div className="OriginInformationField">
              <TextField 
                size="small" 
                id="originCloseTime" 
                label="Close Time" 
                variant="outlined"
                value={formData?.originCloseTime || ''}
                onChange={(e) => handleChange(e, 'originInformation')}
                required
              />
            </div>
            <div className="OriginInformationField">
              <TextField 
                size="small" 
                id="originPickupNumber" 
                label="Pickup Number" 
                variant="outlined"
                value={formData?.originPickupNumber || ''}
                onChange={(e) => handleChange(e, 'originInformation')}
                required
              />
            </div>
            <div className="OriginInformationField">
              {/*}
              <label>
                <Checkbox checked={isCheckboxChecked} onChange={handleCheckboxChange}/>
                Is dock first come first serve?
              </label>
              <*/}
              <TextField
                size="small" 
                id="originDockFirstServe" 
                label="Include all pick-up information here" 
                variant="outlined"
                value={formData?.originDockFirstServe || ''}
                onChange={(e) => handleChange(e, 'originInformation')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OriginInformation