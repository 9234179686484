import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const FileUpload = ({ id, onFileSelect }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const newUniqueId = uuidv4();
    const truncatedUniqueId = newUniqueId.substring(0, 3);
    const originalFile = event.target.files[0];
  
    const newFileName = `${truncatedUniqueId}__${originalFile.name}`;
    const newFile = new File([originalFile], newFileName, { type: originalFile.type });
  
    setFile(newFile);
    onFileSelect(id, newFile);
  };
  

  const handleButtonClick = () => { 
    document.getElementById(`fileInput-${id}`).click(); 
  };

  return (
    <div>
      <input 
        type="file" id={`fileInput-${id}`}
        style={{ display: 'none' }} onChange={handleFileChange} 
      />
      {file ? (
        <p>{file.name}</p>
      ) : (
        <Button variant="outlined" onClick={handleButtonClick}>
          Upload File
        </Button>
      )}
    </div>
  );
};

export default FileUpload;
