import { useState, useEffect } from 'react';
import { getSelectData } from '../../../utils/api/data';

const useDestinationData = (initialFormData, onChange) => {
  const [localFormData, setLocalFormData] = useState(initialFormData);

  useEffect(() => {
    const getOriginData = async () => {
      try {
        const response = await getSelectData({
          selectValues:  'CONSIGNEE_NAME, CONSIGNEE_ADDRESS_1, CONSIGNEE_CONTACT, CONSIGNEE_PHONE, CONSIGNEE_EMAIL, OPEN_TIME, CLOSE_TIME, DELIVERY_NUMBER',
          tableName: 'CustomerPortal.forms.BIOVATIONS_VENDORS_DESTINATION_INFORMATION ',
          whereField: 'CONSIGNEE_NAME',
          whereValue: 'Biovation HQ',
        });
        
        if (response && response.length > 0) {
          const consigneeData = response[0]; // Assuming response is an array and we're interested in the first item
          const updatedFormData = {
            consigneeName: consigneeData.CONSIGNEE_NAME,
            consigneeAddress1: consigneeData.CONSIGNEE_ADDRESS_1,
            consigneeContact: consigneeData.CONSIGNEE_CONTACT,
            consigneePhone: consigneeData.CONSIGNEE_PHONE,
            consigneeEmail: consigneeData.CONSIGNEE_EMAIL,
            consigneeOpenTime: consigneeData.OPEN_TIME,
            consigneeCloseTime: consigneeData.CLOSE_TIME,
            deliveryNumber: consigneeData.DELIVERY_NUMBER
          };
          setLocalFormData(prevState => ({ ...prevState, ...updatedFormData }));
          onChange({ ...initialFormData, ...updatedFormData });
        }
      } catch (error) {
        console.error("Error fetching origin data:", error);
      }
    };

    getOriginData();
  }, []);

  const handleChange = (e) => {
    let { id, value } = e.target;
    setLocalFormData(prevState => ({ ...prevState, [id]: value }));
    onChange({ ...localFormData, [id]: value });
  };

  return {
    localFormData,
    handleChange,
  };
};

export default useDestinationData;
