import CustomizedTitle from '../../components/customizedTitle/CustomizedTitle2';
import AuditChartFilter from '../../components/charts/filter/AuditChartFilter';
import StackedBar from '../../components/charts/bar/stackedBar/StackedBar';
import ToggleButton from '../../components/common/ToggleButton';
import CircularProgress from '@mui/material/CircularProgress';
import Widget from '../../components/common/widget/Widget';
import Sidebar from '../../components/sidebar/Sidebar';
import verifyToken from "../../utils/tokenUtils.js";
import NavBar from '../../components/navbar/NavBar';
import React, { useEffect, useState } from 'react';
import { config } from '../../config/Constants';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { saveAs } from 'file-saver';
import Cookies from 'js-cookie';
import './auditCenter.css';
import Axios from 'axios';

const AuditCenter = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [tableSelect, setTableSelect] = useState('');
  const [toggle, setToggle] = useState('UPS');
  const [auditChartData, setAuditChartData] = useState([]);
  const [auditFilter, setAuditFilter] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingChart, setIsLoadingChart] = useState(false);
  const titleObject = {title: 'Parcel Audit Center'};

  const toggleObject = {
    toggleButtons: ['UPS', 'FedEx'],
    rows: rows,
    tableSelection: tableSelect,
  }

  const toggleValue = (value) => {
    setToggle(value);
  }

  const getCompany = () => {
    return Cookies.get('cp-cn');
  }

  const getAuthToken = () => {
    return Cookies.get('gtms-cp-auth');
  };

  useEffect(() => {
    const token = getAuthToken();
    token ? verifyToken(token) : navigate('/');
  }, []);

  useEffect(() => {
    getChartData();
  }, [toggle]);

  const getChartData = async() => {
    const companyName = getCompany();
    const response = await Axios.get(config.url.GET_AUDIT_CHART_DATA, {
      params: {
        companyName: companyName,
        carrier: toggle,
      }
    })
    setAuditChartData(response.data.chartData);
  }

  const getFilteredChartData = async(value) => {
    const companyName = getCompany();
    const response = await Axios.get(config.url.GET_FILTER_AUDIT_CHART_DATA, {
      params: {
        companyName: companyName,
        startDate: value.startDate,
        endDate: value.endDate,
        serviceDescription: value.serviceDescription,
        carrier: toggle,
      }
    });
    return response.data.chartData;
  }

  const handleAuditFilter = async(value) => {
    setIsLoadingChart(true);
    setAuditFilter(value);
    const auditData = await getFilteredChartData(value);
    setIsLoadingChart(false);
    setAuditChartData(auditData);
  }

  const getDownloadData = async() => {
    const companyName = getCompany();
    const response = await Axios.get(config.url.GET_DOWNLOAD_AUDIT, {
      params: {
        auditFilter: auditFilter,
        companyName: companyName,
        carrier: toggle,
      }, 
      responseType: 'blob',
    });

    const blob = new Blob([response.data], { type: 'text/csv' });
    saveAs(blob, companyName + 'AuditDownload.csv');
  }

  const handleDownload = async() => {
    setIsLoading(true);
    await getDownloadData();
    setIsLoading(false);
  }

  return (
  <div className="Page">
    <div className="PageContainer">
      <NavBar/>
      <Sidebar/>
      <div className="auditCenterPageContent">
        <div className="auditCenterPageContentTop">
          <CustomizedTitle titleObject={titleObject}/>
        </div>
        <div className="auditCenterPageContentMid">
          <div className="auditCenterWidgets">
            <Widget type={{widgetId: "1", carrier: toggle}}/>
            <Widget type={{widgetId: "2", carrier: toggle}}/>
          </div>
          <div className="auditCenterBarGraph">
            <div className="auditCenterButtons">
              <div className="auditCenterToggles">
                <ToggleButton
                  toggleValue={toggleValue}
                  toggleObject={toggleObject} />
              </div>
              <div className="auditCenterDownload">
                <Button variant="outlined" onClick={handleDownload} disabled={isLoading}>
                  {isLoading ? <CircularProgress size={24} /> : 'Download Last 30 Days'}
                </Button>
                <div className="auditCenterChartFilter">
                  <AuditChartFilter handleAuditFilter={handleAuditFilter}/>
                </div>
              </div>
            </div>
            {isLoadingChart ? (
              <div className='centerProgress'>
                <CircularProgress size={100} />
              </div>
              ) :
            <div className="auditCenterChart">
              <div className="auditChartTitle">
                <h3>{toggle} Parcel Audit</h3>
              </div>
                <StackedBar chartData={auditChartData}/>
            </div>
                }
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default AuditCenter