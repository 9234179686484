import { configureStore } from '@reduxjs/toolkit';
import sidebarReducer from './slices/sidebarSlice'; 
import chartFilterReducer from './slices/chartfilterSlice';
import summaryDataReducer from './slices/summarydataSlice';

const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    chartfilter: chartFilterReducer,
    summaryData: summaryDataReducer,
  },
});

export default store;