import useTransformChartData from '../../../hooks/data/useTransformChartData';
import SummaryData from '../text/SummaryData';
import PieChart from './piechart';
import './piechartsummary.css';
import React from 'react';

const PieChartSummary = ({chartData, secondaryChart}) => {

  const data = useTransformChartData(chartData);
  const secondaryData = useTransformChartData(secondaryChart);

  return (
    <div className="chartd">
      <div className='pieChartSummaryContainer'>
        <div className='half'>
          <PieChart chartData={data} />
        </div>
        <div className='half'>
          <div className='pieChartSummary'>
            <SummaryData chartData={secondaryData}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PieChartSummary